<div class="card dialog-box">
  <div class="card-header">
    {{ updateView ? "Edit Monitoring Company" : "Add New Monitoring Company" }}
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div>
    <form [formGroup]="companyForm">
      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="text"
          id="company_name"
          formControlName="company_name"
          placeholder="Company Name"
          autocomplete="off"
        />
        <label for="company_name">Company Name</label>
      </div>

      <div class="row g-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-darkn-light w-100"
            (click)="onCloseDialogue('close')"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-primary w-100"
            (click)="updateData()"
            [disabled]="!companyForm.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
