import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { DIALOG_DATA } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { JobsService } from '../../../pages/pages/jobs/jobs.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { AddEditClientComponent } from '../add-edit-client/add-edit-client.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { SaveNewAddressComponent } from '../save-new-address/save-new-address.component';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';

@Component({
  selector: 'gtapp-add-edit-site',
  templateUrl: './add-edit-site.component.html',
  styleUrl: './add-edit-site.component.scss',
})
export class AddEditSiteComponent implements OnInit {
  @Input() detailData: any;
  @Input() siteAddress: any;
  currentStepIndex: number = 1;
  @Input() editSite: any;
  @Input() showFrom: any;
  @Input() siteName: boolean = true;
  @ViewChild(AutoCompleteComponent)
  autoCompleteComponent!: AutoCompleteComponent;

  siteForm: UntypedFormGroup = new UntypedFormGroup({
    addressLookup: new UntypedFormControl(''),
    company: new UntypedFormControl(null, Validators.required),
    company_name: new UntypedFormControl(null, Validators.required),
    address1: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(''),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    country_name: new UntypedFormControl('Australia'),
    state_code: new UntypedFormControl(''),
  });
  searchControl: FormControl = new FormControl();
  addressValue: any;
  addressObjectValue: any;
  addrLookupData: any;
  stateData: any = [];
  firstAddress: any;
  latLon = { lat: 0, lon: 0 };
  customerList: any;
  clientTypedValue: any;
  dialogref: any;
  notGetExactAddress: boolean = false;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  showAddressDropdown: boolean = false;
  showClientDropdown: boolean = false;
  clientValue: any;
  selectedClient: any;
  siteAddressValue: any = '';
  currentEditSiteStep = 1;
  constructor(
    @Optional() protected dialogRef: DialogRef,
    private customerService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private profileService: ProfileService,
    private appService: AppService,
    private dialogService: ModelDialogueService,
    private jobService: JobsService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private toasterService: ToasterService
  ) {
    this.searchControl.valueChanges.subscribe((data: any) => {
      if (typeof data === 'object') {
        this.searchControl.setValue(data.full_address);
      }
    });
  }

  ngOnInit(): void {
    if (this.dialogueData) {
      this.detailData = this.dialogueData?.detailData;
      this.showFrom = this.dialogueData?.showFrom;
      this.siteAddress = this.dialogueData?.siteAddress;
      this.editSite = this.dialogueData?.editSite;
      this.siteName = this.dialogueData?.siteName;
      if (this.siteName === false) {
        this.currentEditSiteStep = 2;
      }
    }
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById('site_name');
      if (element) {
        element?.focus();
      }
    }, 1);
    if (this.showFrom === 'addJob') {
      this.getClientList();
    }

    this.formatSiteData(this.detailData);

    this.getStates(1);
    if (this.siteAddress) this.addressValue = this.siteAddress;
    if (this.showFrom === 'addJob') {
      this.shiftFocus('clientInputField');
    } else {
      this.shiftFocus('addressLookupField');
    }
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }

  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      if (this.lastSearchStr) {
        !this.searchControl.value.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.profileService
          .addressLookup(addressSearch.target.value)
          .subscribe((res: any) => {
            this.showAddressDropdown = true;
            this.addrLookupData = res['data'];
            this.firstAddress = res['data']?.[0];
            this.lastApiResponseHadData = res['data'].length > 0;

            this.lastSearchStr = this.searchControl.value;
          });
      }
    }
  }
  formatSiteData(data: any) {
    this.detailData = data;
    this.siteForm.patchValue(this.detailData);
    this.siteForm.controls['address1'].setValue(
      this.detailData?.address?.address1
    );
    this.siteForm.controls['address2'].setValue(
      this.detailData?.address?.address2
    );
    this.siteForm.controls['address3'].setValue(
      this.detailData?.address?.address3
    );
    this.siteForm.controls['city_name'].setValue(
      this.detailData?.address?.city_name
    );
    this.siteForm.controls['country_name'].setValue(
      this.detailData?.address?.country_name
    );
    this.siteForm.controls['postcode'].setValue(
      this.detailData?.address?.postcode
    );
    this.siteForm.controls['state_code'].setValue(
      this.detailData?.address?.state_code
    );
    this.siteForm.controls['latitude'].setValue(
      this.detailData?.address?.latitude
    );
    this.siteForm.controls['longitude'].setValue(
      this.detailData?.address?.longitude
    );
    this.siteForm.controls['company_name'].enable();
  }
  onCloseDialogue(data: any = 'close') {
    if (data != 'close') {
      data.selectedClient = this.selectedClient;
    }

    this.dialogRef.close(data);
  }
  addressSelected(address: any, addressFrom?: any) {
    if (address.key === 0) {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.addrLookupData = undefined;
      this.addressValue = address?.full_address;
      this.addressObjectValue = address;
      this.siteForm.patchValue({
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country_name: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
      });
      if (addressFrom === 'newAddress') {
        this.notGetExactAddress = true;
      }
    }
  }
  updateData() {
    this.spinnerService.show();
    if (this.siteForm.valid) {
      var requestData = { data: this.siteForm.getRawValue() };

      if (this.detailData?.id) {
        this.spinnerService.show();
        this.customerService
          .updateSiteById(requestData, this.detailData?.id)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response['data']);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      } else {
        this.spinnerService.show();
        this.customerService
          .addSiteInClient(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response['data']);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    } else {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Please fil the required fields',
      });
    }
  }
  addPlace() {
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value, 'newAddress');
          if (this.searchControl.value === ' ') {
            this.searchControl.setValue(
              value?.address1 +
                ' ' +
                value?.address2 +
                ' ' +
                value?.city_name +
                ' ' +
                value?.state_code +
                ' ' +
                value?.postcode
            );
          }
        }
      } else {
        this.searchControl.setValue('');
      }
    });
  }
  forceUppercaseConditionally(event: any) {
    this.siteForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }
  getClientList() {
    this.customerService.fetchCustomerList().subscribe((response: any) => {
      if (response['status'] === 'success') {
        this.showClientDropdown = true;
        this.customerList = response['data'];
      }
    });
  }

  onClientSearch(event: any) {
    this.clientTypedValue = event.target.value;
    this.jobService
      .getSearchClients(this.clientTypedValue)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.customerList = res['data'];
        }
      });
  }
  onClientSelect(data: any) {
    if (data.key === 0) {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.siteForm.controls['company'].setValue(data?.id);
      this.selectedClient = data?.company_name;
      this.clientValue = data?.company_name;
      var element = <HTMLInputElement>(
        document.getElementById('clientInputField')
      );

      if (element) {
        element.disabled = false;
        element.blur();
        this.showClientDropdown = false;
      }
    }
  }
  openAddCustomerForm() {
    this.dialogref = this.dialogService.open(AddEditClientComponent, {
      data: { customerName: this.clientTypedValue },
    });

    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        this.onClientSelect(value?.data);
        // this.value = value?.data?.company_name;
      }
    });
  }
  editAddressLookup(event: any) {
    this.siteAddressValue = '';
    this.addressValue = '';
    this.autoCompleteComponent.clearInput();
    this.siteForm.reset();
    this.siteForm.updateValueAndValidity();
  }
}
