<div [ngClass]="previewAndCloseOnImage ? 'card border-0' : ''">
  <div class="card-header" *ngIf="previewAndCloseOnImage">
    Tap to Zoom
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div *ngIf="previewAndCloseOnImage" class="text-center">
    <span *ngIf="timeStamp"
      >Uploaded At : {{ timeStamp | date : "d MMM y HH:mm" }}</span
    >
  </div>
  <div [ngClass]="previewAndCloseOnImage ? 'image-wrapper' : ''">
    <img #img src="{{ imageSrc }}" alt="Image Not Found" />
  </div>
  <div #zoomLevel class="zoom-level"></div>
</div>
