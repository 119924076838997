<div class="card dialog-box">
  <div class="card-header">
    Add Security Licence

    <span class="float-end" (click)="closeDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div>
    <form [formGroup]="licenseForm">
      <!-- State Dropdown -->
      <div class="form-floating mb-3">
        <select
          class="form-select form-select-lg"
          id="issuer_state"
          formControlName="issuer_state"
          (change)="selectState($event)"
        >
          <option
            *ngFor="let state of stateData"
            [value]="state.id"
            [attr.data-code]="state.code"
          >
            {{ state.code }}
          </option>
        </select>
        <label for="issuer_state">State</label>
      </div>

      <!-- Licence Class / Category -->
      <div class="mb-3">
        <gtapp-auto-complete
          [data]="licenseClassData"
          placeHolder="Licence Class / Category"
          searchKeyword="license_class_name"
          [initialValue]="licenseClassName"
          [itemTemplate]="licenceTemplate"
          [addInitialKey]="true"
          (inputChanged)="onLicenceClassSearch($event)"
          (selected)="onlicenseSelect($event)"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #licenceTemplate let-item>
        <div>
          {{ item.license_class_name }}
        </div>
      </ng-template>

      <!-- Security Licence ID Number -->
      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="text"
          id="license_number"
          formControlName="license_number"
          autocomplete="off"
          placeholder="Security Licence ID Number"
        />
        <label for="license_number">Security Licence ID Number</label>
      </div>

      <!-- Licence Expiry Date -->
      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="date"
          id="valid_till"
          formControlName="valid_till"
          placeholder="Licence Expiry"
          [min]="validDate"
        />
        <label for="valid_till">Licence Expiry</label>
      </div>

      <!-- Action Buttons -->
      <div class="row row-cols-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark w-100 btn-lg"
            (click)="closeDialogue()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-primary w-100 btn-lg"
            [disabled]="!licenseForm.valid"
            (click)="addLicense()"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
