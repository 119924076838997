<!-- <div *ngIf="appliedFilters?.length">
  <div class="tag-list">
    <span class="tag" *ngFor="let filter of appliedFilters">
      {{ filter?.key }} : {{ filter?.value }}
      <button class="btn-close" (click)="removeFilter(filter?.key)"></button>
    </span>
  </div>
</div> -->
<ng-container *ngIf="filterSortData?.length">
  @if(selectedCardDetail && filterSortData?.length===1){
  <div class="input-group my-3">
    <div
      class="col form-floating"
      *ngIf="selectedCardDetail?.dateRangeKey"
      (click)="openRangePicker(selectedCardDetail)"
    >
      <input
        class="form-control input-font-sm"
        placeholder="Pick Date Range [{{
          selectedCardDetail | misc : 'getFilterHeader' | titlecase
        }}]"
        readonly
        [(ngModel)]="selectedCardDetail.dateRangeValue"
      />
      <label
        >Pick Date Range [{{
          selectedCardDetail | misc : "getFilterHeader" | titlecase
        }}]</label
      >
    </div>

    <div *ngIf="selectedCardDetail?.opendropDownBox" class="col form-floating">
      <select
        class="form-select"
        placeholder="Status"
        (selectedChange)="selectStatus($event, selectedCardDetail)"
      >
        <div *ngFor="let status of selectedCardDetail?.dropDownList()">
          {{ status?.name }}
        </div>
      </select>
    </div>
    <div *ngIf="selectedCardDetail?.searchKey" class="col form-floating">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="selectedCardDetail.searchValue"
        placeholder="Search"
        (keyup.enter)="searchField(selectedCardDetail)"
      />
      <label
        >Search
        {{ selectedCardDetail | misc : "getFilterHeader" | titlecase }}</label
      >
    </div>

    <span
      class="input-group-text"
      *ngIf="
        !selectedCardDetail?.openSearchBox &&
        selectedCardDetail?.sortKey &&
        cardData?.length
      "
      (click)="sortField(selectedCardDetail)"
    >
      <i class="fa fa-sort"></i>
    </span>
  </div>
  } @else {
  <!-- <div class="filter-button">
    <button class="btn btn-dark" (click)="openTemplate(filterTemplateRef)">
      <span><i class="fa-solid fa-filter text-white"></i></span>
    </button>
  </div> -->
  <ng-container *ngFor="let selectedFilter of filterSortData">
    <div class="input-group mt-3 mb-3">
      <!-- Date Range Picker -->
      <div
        class="col form-floating"
        *ngIf="selectedFilter?.dateRangeKey"
        (click)="openRangePicker(selectedFilter)"
      >
        <input
          class="form-control input-font-sm"
          size="lg"
          placeholder="Pick Date Range [{{
            selectedFilter | misc : 'getFilterHeader' | titlecase
          }}]"
          readonly
          [(ngModel)]="selectedFilter.dateRangeValue"
        />
        <label
          >Pick Date Range [{{
            selectedFilter | misc : "getFilterHeader" | titlecase
          }}]</label
        >
      </div>

      <!-- Dropdown Box -->
      <div *ngIf="selectedFilter?.opendropDownBox" class="col form-floating">
        <select
          class="form-select"
          placeholder="Status"
          (selectedChange)="selectStatus($event, selectedFilter)"
        >
          <option *ngFor="let status of selectedFilter?.dropDownList()">
            {{ status?.name }}
          </option>
        </select>
        <label>Status</label>
      </div>

      <!-- Search Box -->
      <div *ngIf="selectedFilter?.searchKey" class="col form-floating">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="selectedFilter.searchValue"
          placeholder="Search"
          (keyup.enter)="searchField(selectedFilter)"
        />
        <label
          >Search
          {{ selectedFilter | misc : "getFilterHeader" | titlecase }}</label
        >
      </div>

      <!-- Sort Icon -->
      <span
        class="input-group-text"
        *ngIf="!selectedFilter?.openSearchBox && selectedFilter?.sortKey"
        (click)="sortField(selectedFilter)"
        style="cursor: pointer"
      >
        <i class="fa fa-sort"></i>
      </span>
    </div>
  </ng-container>

  <!-- Show Header Toggle -->
  <div class="form-check form-switch" *ngIf="isSuperUser">
    <input
      class="form-check-input"
      type="checkbox"
      [checked]="showHeader"
      data-toggle="toggle"
      (change)="showHideHeader($event)"
      id="header"
    />
    <label class="form-check-label" for="header">Show Header</label>
  </div>

  }

  <!-- <ng-container *ngIf="filterSortData?.length > 1">
    <div class="mt-2 mb-2 d-flex justify-content-around">
      <button (click)="showNextCardDetail()" class="btn btn-primary">
        <i class="fa fa-arrow-down" aria-hidden="true"></i>
      </button>
    </div>
  </ng-container> -->
</ng-container>

<div
  class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxxl-6 mb-3"
>
  <!-- Card Sections split into multiple sections based on screen size -->
  <!-- row-cols-md-2 row-cols-xxl-3 row-cols-xxxl-6 -->
  <div
    *ngFor="let data of cardData; let i = index"
    class="col h-100 w-100 gt-card"
  >
    <div class="d-flex justify-content-between" (click)="onRowClick(data)">
      <div class="flex-grow-1">
        <div
          *ngFor="let indCard of mobileCardDetails?.individualDetails"
          class="d-block"
        >
          <!-- Style whole row either based on condition or common to all -->
          <span
            [ngStyle]="
              indCard?.conditionalRowStyle
                ? indCard?.conditionalRowStyle(data)
                : indCard?.cardRowStyle
            "
          >
            <span
              *ngIf="
                showHeader &&
                (indCard.nestedValue
                  ? indCard.nestedValue(data)
                  : data[indCard.name]) != null
              "
              [ngStyle]="
                indCard?.conditionalHeaderRowStyle
                  ? indCard?.conditionalHeaderRowStyle(data)
                  : indCard?.cardHeaderRowStyle
              "
            >
              <!-- Header content which will be passed from the backend STARTS-->
              <dt
                [ngStyle]="
                  indCard?.conditionalHeaderRowDataStyle
                    ? indCard?.conditionalHeaderRowDataStyle(data)
                    : indCard?.rowHeaderDataStyle
                "
              >
                {{
                  indCard.nestedHeaderValue
                    ? indCard.nestedHeaderValue(data)
                    : indCard.header
                }}
              </dt>
              <!-- Header content which will be passed from the backend ENDS-->
            </span>
            <!-- A row is typically divided into 3 parts: prefix, main content, and suffix. Style each of them based on conditions or common styles -->
            <span
              [ngStyle]="
                indCard?.conditionalPrefixStyle
                  ? indCard?.conditionalPrefixStyle(data)
                  : indCard?.prefixStyle
              "
            >
              <!-- Prefix (either hardcoded from UI or passed from backend via key-value pair) STARTS -->
              {{
                indCard?.prefixNestedValue
                  ? indCard?.prefixNestedValue(data)
                  : indCard?.prefix
                  ? indCard?.prefix
                  : ""
              }}
            </span>
            <!-- Prefix (either hardcoded from UI or passed from backend via key-value pair) ENDS -->
            <!-- Main content which will be passed from the backend STARTS -->
            <span
              [ngStyle]="
                indCard?.conditionalRowDataStyle
                  ? indCard?.conditionalRowDataStyle(data)
                  : indCard?.rowDataStyle
              "
            >
              <ng-container
                *ngIf="
                  indCard?.imageObj === true &&
                  (indCard.nestedValue
                    ? indCard.nestedValue(data)
                    : data[indCard.name])
                "
              >
                <img
                  [ngStyle]="
                    indCard?.conditionalImageStyle
                      ? indCard?.conditionalImageStyle(data)
                      : indCard?.imageStyle
                  "
                  src="{{
                    indCard.imageSrc
                      ? indCard.imageSrc
                      : indCard.nestedValue
                      ? indCard.nestedValue(data)
                      : data[indCard.name]
                  }}"
                />
              </ng-container>
              <ng-container *ngIf="indCard?.datetimeObj == true">
                {{
                  indCard.nestedValue
                    ? (indCard.nestedValue(data)
                      | customDate
                        : (indCard.dateTimeFormat
                            ? indCard.dateTimeFormat
                            : "d MMM y HH:mm"))
                    : (data[indCard.name]
                      | customDate
                        : (indCard.dateTimeFormat
                            ? indCard.dateTimeFormat
                            : "d MMM y HH:mm"))
                }}</ng-container
              >
              <ng-container *ngIf="!indCard?.datetimeObj && !indCard?.imageObj">
                {{
                  indCard.nestedValue
                    ? indCard.nestedValue(data)
                    : data[indCard.name]
                }}</ng-container
              >
            </span>
            <!-- Main content which will be passed from the backend ENDS -->
            <!-- Suffix (either hardcoded from UI or passed from backend via key-value pair) STARTS -->
            <span
              [ngStyle]="
                indCard?.conditionalSuffixStyle
                  ? indCard?.conditionalSuffixStyle(data)
                  : indCard?.suffixStyle
              "
            >
              {{
                indCard?.suffixNestedValue
                  ? indCard?.suffixNestedValue(data)
                  : indCard?.suffix
                  ? indCard?.suffix
                  : ""
              }}
            </span>
            <!-- Suffix (either hardcoded from UI or passed from backend via key-value pair) ENDS -->
          </span>
          <!-- Rows where objects are normal objects ENDS -->
        </div>

        <!-- Call Guard Button -->
        <ng-container *ngIf="showCallGuardButton">
          <!-- Add two call buttons; one disabled if there is no phone number -->
          <ng-container *ngIf="data?.mobile_number">
            <a
              href="tel:{{ data?.mobile_number }}"
              class="text-white text-decoration-none"
            >
              <button
                class="btn btn-success w-100 mt-2 d-flex align-items-center justify-content-center"
              >
                <i class="fa-solid fa-phone-volume me-2"></i>
                <span>Call</span>
              </button>
            </a>
          </ng-container>
          <ng-container *ngIf="!data?.mobile_number">
            <div
              title="{{ data?.full_name }} has not provided a phone number"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <button
                class="btn btn-success w-100 mt-2 d-flex align-items-center justify-content-center"
                disabled
              >
                <i class="fa-solid fa-phone-volume me-3"></i>
                Call
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>

      @if(mobileActionIcons){
      <div class="d-flex flex-column ms-auto ps-2 gap-2">
        <div *ngFor="let actionIcon of mobileActionIcons">
          <span
            class="text-end"
            (click)="
              onActionClick(data, actionIcon.condition(data).type);
              $event.stopImmediatePropagation()
            "
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="{{ actionIcon.condition(data).title }}"
          >
            <i [class]="actionIcon.condition(data).icon"></i>
          </span>
        </div>
      </div>
      }
    </div>
  </div>
</div>
<div *ngIf="otherParams?.paginationData">
  <gtapp-pagination-control
    [pageSize]="rows"
    [pageNum]="pageNum"
    [previous]="previousRows"
    [totalRows]="totalRows"
    (onChangePagination)="onChangePagination($event)"
  >
  </gtapp-pagination-control>
</div>

<div *ngIf="cardData?.length == 0" class="my-3">
  <div class="alert alert-primary" role="alert">No Records Found</div>
</div>

<ng-template #filterTemplateRef let-filterTemplateRef="dialogRef">
  <div class="dialog-box card">
    <ng-container *ngFor="let selectedFilter of filterSortData">
      <div class="input-group mb-3">
        <div
          class="col form-floating"
          *ngIf="selectedFilter?.dateRangeKey"
          (click)="openRangePicker(selectedFilter)"
        >
          <input
            class="form-control"
            size="lg"
            placeholder="Pick Date Range [{{
              selectedFilter | misc : 'getFilterHeader' | titlecase
            }}]"
            readonly
            [(ngModel)]="selectedFilter.dateRangeValue"
          />
          <label
            >Pick Date Range [{{
              selectedFilter | misc : "getFilterHeader" | titlecase
            }}]</label
          >
        </div>
        <div *ngIf="selectedFilter?.opendropDownBox" class="col form-floating">
          <select
            class="form-select"
            placeholder="Status"
            (selectedChange)="selectStatus($event, selectedFilter)"
          >
            <div *ngFor="let status of selectedFilter?.dropDownList()">
              {{ status?.name }}
            </div>
          </select>
        </div>
        <div *ngIf="selectedFilter?.searchKey" class="col form-floating">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="selectedFilter.searchValue"
            placeholder="Search"
            (keyup.enter)="searchField(selectedFilter)"
          />
          <label
            >Search
            {{ selectedFilter | misc : "getFilterHeader" | titlecase }}</label
          >
        </div>

        <span
          class="input-group-text"
          *ngIf="!selectedFilter?.openSearchBox && selectedFilter?.sortKey"
          (click)="sortField(selectedFilter)"
        >
          <i class="fa fa-sort"></i>
        </span>
      </div>
    </ng-container>
    <label for="header">
      <input
        type="checkbox"
        [checked]="showHeader"
        data-toggle="toggle"
        (change)="showHideHeader($event)"
        id="header"
      />
      Show Header</label
    >
    <div>
      <button class="btn w-100" (click)="filterTemplateRef.close()">
        Close
      </button>
    </div>
  </div>
</ng-template>
