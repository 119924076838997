import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private message = new BehaviorSubject<any>({
    successMessage: '',
    errorMessage: '',
    timeOut: 5000,
    status: null,
  });
  constructor() {}
  setMessage(message: any) {
    this.notifyUser();
    this.message.next(message);
  }
  returnMessage() {
    return this.message.asObservable();
  }
  playChord(duration: any, frequencies: any, volume: any) {
    return new Promise<void>((resolve, reject) => {
      duration = duration || 200;
      volume = volume || 100;

      try {
        const myAudioContext = new AudioContext();
        let gainNode = myAudioContext.createGain();
        gainNode.connect(myAudioContext.destination);
        gainNode.gain.value = volume * 0.01;

        frequencies.forEach((frequency: number) => {
          if (myAudioContext.state === 'suspended') {
            myAudioContext.resume();
          }

          let oscillatorNode = myAudioContext.createOscillator();
          oscillatorNode.connect(gainNode);
          oscillatorNode.frequency.value = frequency;
          oscillatorNode.type = 'sine';
          oscillatorNode.start(myAudioContext.currentTime);
          oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);
        });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  getUserData() {
    return JSON.parse(localStorage.getItem('userData') || '{}');
  }
  getNotificationVolume() {
    let volume = 30;
    const userPreferences = this.getUserData()?.preferences || {};
    // the variable used will seem strange. Initally there was only mute and unmute option. Recycling the key so that it wont be wasted.
    if ('mute_notification' in userPreferences) {
      if (userPreferences?.mute_notification === true) {
        volume = 0;
      } else if (userPreferences?.mute_notification === false) {
        volume = 30;
      } else {
        volume = userPreferences?.mute_notification;
      }
    } else {
      volume = 30;
    }
    return volume;
  }
  async notifyUser(actionResult: string = 'success') {
    let volume = this.getNotificationVolume();

    if (volume) {
      if (actionResult === 'success') {
        this.playChord(200, [1318.51], volume);
      } else {
        this.playChord(200, [200], volume);
      }
    }
  }
}
