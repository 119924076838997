import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicScriptLoadService {
  private scripts: any = {};

  constructor() {
    this.scripts = {
      'google-recaptcha': {
        loaded: false,
        src: 'https://www.google.com/recaptcha/api.js?render=explicit&onload=initRecaptcha',
      },
      'ol-maps': {
        loaded: false,
        src: 'https://openlayers.org/en/v4.6.5/build/ol.js',
      },
      'square-script': {
        loaded: false,
        src: 'https://sandbox.web.squarecdn.com/v1/square.js',
      },
      'square-script-prod': {
        loaded: false,
        src: 'https://web.squarecdn.com/v1/square.js', //production payment script
      },
      'jquery': {
        loaded: false,
        src: 'https://code.jquery.com/jquery-3.5.1.min.js',
      },
      'org-chart': {
        loaded: false,
        src: 'https://cdnjs.cloudflare.com/ajax/libs/orgchart/2.2.0/js/jquery.orgchart.min.js',
        link: 'https://cdnjs.cloudflare.com/ajax/libs/orgchart/2.2.0/css/jquery.orgchart.css',
      },
    };
  }

  load(scripts: any, cssOnly?: any) {
    var promises: any[] = [];
    if (!cssOnly) {
      if (typeof scripts == 'string') scripts = [scripts];
      scripts.forEach((script: any) => promises.push(this.loadScript(script)));
    } else {
      promises.push(this.loadCSS(scripts.link, scripts?.id));
    }
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load script
        let script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
        if (this.scripts[name].link) this.loadCSS(this.scripts[name].link);
      }
    });
  }

  loadCSS(url: any, id?: any) {
    let link;
    if (id) link = document.getElementById(id);
    if (link) link.id = id;
    // Create link
    link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    link.type = 'text/css';

    let head = document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName('link');
    // Check if the same style sheet has been loaded already.
    let isLoaded = false;
    for (var i = 0; i < links.length; i++) {
      var node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) return;
    head.appendChild(link);
  }
}
