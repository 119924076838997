import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  DIALOG_DATA,
  getFormattedDate,
  getFormattedTime,
} from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { DataCheckService } from '../../services/data-check.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../contact/contact.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-event-scheduler',
  templateUrl: './event-scheduler.component.html',
  styleUrl: './event-scheduler.component.scss',
})
export class EventSchedulerComponent implements OnInit {
  clientData: any;
  siteData: any;
  scheduleData: any;

  currentStep = 1;

  repeatTypes = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
  ];

  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    mail_time: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    // start_time: new UntypedFormControl('',null),
    start_day: new UntypedFormControl('', [Validators.required]),
    end_day: new UntypedFormControl(null, []),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, [Validators.required]),
    repeat_details: new UntypedFormControl(null, []),
    repeat_interval: new UntypedFormControl(null, []),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  selectedUsers: any = [];

  startDate: any;

  clientContactList: any = [];
  contactLookupList: any = [];

  constructor(
    @Optional() protected dialogueRef: DialogRef,
    private spinnerService: LoadingSpinnerService,
    private customerService: ClientsService,
    private dialogService: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    private router: Router,
    @Inject(DIALOG_DATA) private dialogData: any
  ) {
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogueRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.currentStep = 1;
    if (this.dialogData) {
      this.clientData = this.dialogData?.clientData;
      this.siteData = this.dialogData?.siteData;
      this.scheduleData = this.dialogData?.scheduleData;
    }

    if (this.scheduleData) {
      this.formatScheduleData(this.scheduleData);
    } else {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setMinutes(0);
      this.schedulerForm.controls['start_day'].setValue(
        getFormattedDate(currentDate)
      );
      this.schedulerForm.controls['mail_time'].setValue(
        getFormattedTime(currentDate)
      );
      this.schedulerForm.controls['repeat_type'].setValue('daily');
    }
    this.startDate = getFormattedDate();
    this.getExistingContacts();
  }
  getExistingContacts() {
    let clientId = this.clientData?.id
      ? this.clientData?.id
      : this.siteData?.company?.id;
    this.customerService
      .searchLocationContacts({ union: 1, company_id: clientId })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.clientContactList = response?.data;

          setTimeout(() => {
            this.makeSelectionChanges();
          }, 100);
        }
      });
  }
  formatMailTime(event: any) {
    let mailTime = new Date();
    let hour = event.target.value.split(':')[0];
    mailTime.setHours(hour, 0, 0, 0);
    this.schedulerForm.controls['mail_time'].setValue(
      getFormattedTime(mailTime)
    );
  }

  goBack(data: any) {
    if (data) {
      this.dialogueRef.close(data);
    } else {
      this.dialogueRef.close(false);
    }
    this.schedulerForm.reset();
    this.selectedUsers = [];
  }

  formatScheduleData(schedule: any) {
    this.schedulerForm.controls['start_day'].setValue(
      schedule?.schedule?.start_day
    );
    this.schedulerForm.controls['name'].setValue(schedule?.name);

    let mail_time = new Date();
    mail_time.setTime(schedule?.mail_time);
    this.schedulerForm.controls['mail_time'].setValue(schedule?.mail_time);
    this.selectedUsers = [];
    this.schedulerForm.controls['repeat_type'].setValue(
      schedule?.schedule?.repeat_type
    );
    this.formatSelectedUsers(schedule?.report_users || []);
  }
  formatSelectedUsers(selectedUsers: []) {
    this.selectedUsers = [];
    selectedUsers?.forEach((contact: any) => {
      let formattedContact = contact;
      if (!contact?.pk && contact?.subscriber_user_id)
        // case where the data is returned from the add contact view
        formattedContact.pk = contact?.subscriber_user_id;

      this.selectedUsers.push(formattedContact);
    });
  }

  onCloseDialogue() {
    this.dialogueRef.close(false);
  }

  addRemoveUser(User: any) {
    User.selected = !User?.selected;
    if (User.selected) {
      this.addUserToSelected(User);
    } else {
      this.removeUserFromSelected(User);
    }
  }

  contactLookup(event: any) {
    this.contactLookupList = this.clientContactList.filter(
      (item: any) =>
        item?.user_full_name?.toLowerCase().includes(event?.target?.value) ||
        item?.user_email?.toLowerCase().includes(event?.target?.value)
    );
  }
  addNewContact() {
    let clientData: any = this.clientData;
    const dialogueRef = this.dialogService.open(ContactComponent, {
      data: {
        clientData: clientData,
        patrolReportUsers: true,
        contactType: 'pc',
        reportId: this.scheduleData?.id,
      },
    });
    dialogueRef.afterClosed().subscribe((value: any) => {
      this.spinnerService.hide();

      if (value !== 'false') {
        if (this.scheduleData) {
          this.formatSelectedUsers(value?.data || []);
        } else if (value?.user_email || value?.email) {
          this.selectedUsers.push(value);
        }
        this.getExistingContacts();
      }
    });
  }

  async getRequestData(listData: any) {
    return new Promise(async (resolve) => {
      if (!listData.length) resolve(null);
      let data: any = {};
      await Promise.all(
        listData.map((item: any) => {
          let contact = {
            first_name: item?.first_name,
            last_name: item?.last_name,
            mobile_number: item?.mobile_number,
          };

          data[item.pk] = contact;
        })
      );

      resolve(data);
    });
  }
  async saveSchedule() {
    this.spinnerService.show();
    if (this.schedulerForm.valid) {
      this.spinnerService.show();
      this.schedulerForm.value.start_day = formatDate(
        this.schedulerForm.value.start_day,
        'yyyy-MM-dd',
        'en'
      );
      let requestData: any = this.schedulerForm?.value;

      requestData['time_offset'] = new Date().getTimezoneOffset();
      requestData['user_ids'] = await this.getRequestData(this.selectedUsers);
      if (this.clientData) {
        this.customerService
          .updateClient(this.clientData?.id, requestData, {
            mail_report: 1,
          })
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.goBack(response);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      } else {
        this.customerService
          .updateSite(this.siteData?.id, requestData, {
            mail_report: 1,
          })
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.goBack(response);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    }
  }
  async updateSchedule() {
    if (this.scheduleData?.id && this.schedulerForm.valid) {
      this.spinnerService.show();
      this.schedulerForm.value.start_day = formatDate(
        this.schedulerForm.value.start_day,
        'yyyy-MM-dd',
        'en'
      );
      let requestData: any = this.schedulerForm?.value;

      requestData['time_offset'] = new Date().getTimezoneOffset();

      requestData['user_ids'] = await this.getRequestData(this.selectedUsers);
      requestData['report_id'] = this.scheduleData?.id;

      if (this.clientData) {
        this.customerService
          .updateClient(this.clientData?.id, requestData, {
            mail_report: 1,
          })
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.goBack(response);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      } else {
        this.customerService
          .updateSite(this.siteData?.id, requestData, {
            mail_report: 1,
          })
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.goBack(response);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    }
  }

  deleteSchdeule() {
    if (this.scheduleData?.id) {
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Are you sure?',
          message:
            'Delete schedule means the contacts wont receive any mails of the patrol updates',
        },
      });
      dialogRef.afterClosed().subscribe((value: any) => {
        if (value === true) {
          if (this.clientData) {
            this.spinnerService.show();
            this.customerService
              .updateClient(
                this.clientData?.id,
                { report_id: this.scheduleData?.id },
                {
                  delete_mail_report: 1,
                }
              )
              .then((response: any) => {
                if (response['status'] == 'success') {
                  this.goBack(response);
                } else {
                  this.toasterService.setMessage({
                    successMessage: '',
                    errorMessage: response['message'],
                  });
                }
                this.spinnerService.hide();
              });
          } else {
            this.spinnerService.show();
            this.customerService
              .updateSite(
                this.siteData?.id,
                { report_id: this.scheduleData?.id },
                {
                  delete_mail_report: 1,
                }
              )
              .then((response: any) => {
                if (response['status'] == 'success') {
                  this.goBack(response);
                } else {
                  this.toasterService.setMessage({
                    successMessage: '',
                    errorMessage: response['message'],
                  });
                }
                this.spinnerService.hide();
              });
          }
        }
      });
    }
  }

  addUserToSelected(contactI: any) {
    if (contactI?.key === 0) {
      this.addNewContact();
    } else {
      let contact = {
        pk: contactI?.pk,
        email: contactI?.user_email,
        first_name: contactI?.user_first_name,
        last_name: contactI?.user_last_name,
        full_name: contactI?.user_full_name,
        location_contact_id: contactI?.location_contact_id,
        mobile_number: contactI?.user_mobile_number,
      };
      if (
        this.selectedUsers?.some(
          (selectedContact: any) => selectedContact?.pk === contact?.pk
        )
      ) {
        this.toasterService.setMessage({
          errorMessage: 'Contact Already Selected',
          successMessage: '',
        });
      } else {
        if (
          !this.selectedUsers?.some(
            (selectedContact: any) => selectedContact?.pk === contact?.pk
          )
        ) {
          this.selectedUsers.push(contact);
        }
      }

      setTimeout(() => {
        this.makeSelectionChanges();
      }, 100);
    }
  }
  removeUserFromSelected(contact: any) {
    this.selectedUsers = this.selectedUsers?.filter(
      (selectedContact: any) => selectedContact?.pk !== contact?.pk
    );

    setTimeout(() => {
      this.makeSelectionChanges();
    }, 100);
  }
  makeSelectionChanges() {
    this.selectedUsers?.forEach((selectedContact: any) => {
      if (
        !this.clientContactList?.some(
          (contact: any) => contact?.pk === selectedContact?.pk
        )
      ) {
        this.clientContactList.push(selectedContact);
      }
    });
    this.clientContactList.forEach((contact: any) => {
      contact.selected = this.selectedUsers?.some(
        (selectedContact: any) => selectedContact?.pk === contact?.pk
      );
    });
    this.clientContactList.sort((a: any, b: any) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });
  }
}
