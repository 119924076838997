import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { AppService } from '../../app.service';
@Injectable({
  providedIn: 'root',
})
export class DataCheckService {
  constructor(private appService: AppService) {}

  getDecodedUserToken() {
    return this.appService.getUserData()?.user_token
      ? jwtDecode(this.appService.getUserData()?.user_token)
      : false;
  }
  //       USER_GROUP_CUSTOM = 0, _("Custom")
  //       USER_GROUP_MANAGERS = 1, _("Managers")
  //       USER_GROUP_GUARDS = 2, _("Guards")
  //       USER_GROUP_DISPATCHERS = 3, _("Dispatchers")
  //       USER_GROUP_ADMINISTRATOR = 4, _("Administrator")

  isUserAdmin(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;

    const userGroups = JSON.parse(decodedToken?.user_groups || '[]');

    return userGroups?.some((_id: any) => [1, 4].includes(_id));
  }

  isDispatchUser(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;

    const userGroups = JSON.parse(decodedToken?.user_groups || '[]');

    return userGroups?.includes(3);
  }
  isGuardUser(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;

    const userGroups = JSON.parse(decodedToken?.user_groups || '[]');

    return userGroups?.includes(2);
  }
  isSuperUser(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;

    return Boolean(decodedToken?.is_superuser);
  }
  hasMultipleSubscriberAssociation(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;

    return Boolean(decodedToken?.has_multiple_association);
  }
  hasPasswordSet(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) {
      this.appService.logOut();
      return false;
    }
    return Boolean(decodedToken?.password_set);
  }
  isTrustedDevice(): boolean {
    return Boolean(this.appService.getUserData()?.token_expiry);
  }
  isSubscriberAdmin(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;
    const userGroups = JSON.parse(decodedToken?.user_groups || '[]');
    return userGroups?.includes(4);
  }
  isGuardUserClockendIn(): boolean {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;
    return Boolean(decodedToken?.clocked_in);
  }
  lastClockedInTime() {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) return false;
    return decodedToken?.clocked_in;
  }
  getCurrentTime() {
    // adding a five min delay here because there is seems to be some second difference in the browser time and server time. Refer #6039
    const fiveMinDelay = 1000 * 60 * 5;
    return new Date().getTime() + fiveMinDelay;
  }

  checkIsSubscriptionOrTestUser() {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) {
      return false;
    }

    const subscriber = decodedToken?.subscriber;

    const currentTime = this.getCurrentTime();

    const testUserValidity = subscriber?.test_user_validity
      ? currentTime <= new Date(subscriber.test_user_validity).getTime()
      : true;

    const trialEndValidity = subscriber?.trial_end_validity
      ? currentTime <= new Date(subscriber.trial_end_validity).getTime()
      : false;

    return (
      (subscriber?.test_user === true && testUserValidity) ||
      trialEndValidity ||
      Boolean(subscriber?.subscription_count?.active_subscription_count > 0)
    );
  }
  checkIsPreviouslySubscribedAndSubscriptionInActive() {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) {
      return false;
    }

    const subscriber = decodedToken?.subscriber;

    const currentTime = this.getCurrentTime();

    const testUserValidity = subscriber?.test_user_validity
      ? currentTime <= new Date(subscriber.test_user_validity).getTime()
      : true;

    const trialEndValidity = subscriber?.trial_end_validity
      ? currentTime <= new Date(subscriber.trial_end_validity).getTime()
      : false;

    return (
      (subscriber?.test_user === true && testUserValidity) ||
      trialEndValidity ||
      Boolean(
        subscriber?.subscription_count?.active_subscription_count > 0 ||
          subscriber?.subscription_count?.active_subscription_count > 0
      )
    );
  }
  isTestAccount() {
    const decodedToken: any = this.getDecodedUserToken();

    if (!decodedToken) {
      return false;
    }
    const subscriber = decodedToken?.subscriber;
    const currentTime = this.getCurrentTime();

    const testUserValidity = subscriber?.test_user_validity
      ? currentTime <= new Date(subscriber.test_user_validity).getTime()
      : true;

    return subscriber?.test_user === true && testUserValidity;
  }
  isTrialAccount() {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) {
      return false;
    }
    const subscriber = decodedToken?.subscriber;

    const currentTime = this.getCurrentTime();

    const trialEndValidity = subscriber?.trial_end_validity
      ? currentTime <= new Date(subscriber.trial_end_validity).getTime()
      : false;

    return trialEndValidity;
  }
  isInGracePeriod() {
    const decodedToken: any = this.getDecodedUserToken();
    if (!decodedToken) {
      return false;
    }

    return decodedToken?.grace_period_remaining;
  }
}
