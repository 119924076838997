<div class="autocomplete-container">
  <div class="input-group">
    <div class="form-floating">
      <input
        class="form-control"
        #searchInput
        type="text"
        placeholder="{{ placeHolder }}"
        [(ngModel)]="query"
        (input)="onChange($event)"
        (focus)="handleFocus($event)"
        (blur)="handleBlur($event)"
        (keyup.enter)="inputSubmitted()"
      />
      <label>{{ placeHolder }}</label>
    </div>
    @if( query && enableSearchCloseOption){
    <span
      class="input-group-text"
      (click)="inputSubmitted()"
      *ngIf="!hideSearchButton"
    >
      <i class="fa-solid fa-magnifying-glass"></i>
    </span>
    <span class="input-group-text" (click)="onCloseSearch()">
      <i class="fa-solid fa-xmark"></i>
    </span>
    }
  </div>
  <!--FilteredList items-->
  <div class="suggestions-container" *ngIf="isFocused && filteredList?.length">
    <ul #filteredListElement>
      <li
        *ngFor="let item of filteredList; let idx = index"
        class="item"
        [ngClass]="{ 'fw-semibold': idx === 0 && addInitialKey === true }"
      >
        <div
          [class.complete-selected]="idx === selectedIdx"
          (click)="selectDropDownOption(item)"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: item
              }
            "
          >
          </ng-container>
        </div>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="isOpen" class="overlay" (click)="handleOverlay()"></div>
