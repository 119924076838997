import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DatetimePickerComponent } from '../datetime-picker/datetime-picker.component';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { formatDateTimeStamp } from '../../../../global.variable';

@Component({
  selector: 'gtapp-gt-table',
  templateUrl: './gt-table.component.html',
  styleUrl: './gt-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GtTableComponent implements OnInit {
  @Output() actionClick = new EventEmitter();
  @Output() rowClick = new EventEmitter();
  @Output() searchColumn = new EventEmitter();
  @Output() sortColumn = new EventEmitter();
  @Output() paginationChange = new EventEmitter();

  @Input() desktopTableUIData: any;
  @Input() desktopTableBackendData: any;
  @Input() desktopTableDetails: any;

  @Input() tableStyle: any;
  @Input() otherParams: any;
  @Input() tableActionIcons: any;

  apiFiltering: boolean = false;
  openSearchBox: boolean = false;
  selectedIndex: any;

  desktopTableDataTemp: any;
  tableData: any;
  appliedFilters: any = [];

  sortKeys: any;
  dialogeRef: any;

  saveFilters: boolean = false;

  //pagination params

  totalRows: number = 0;
  previousRows: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  selectedStatus: any;

  constructor(private dialogService: ModelDialogueService) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    // this.appliedFilters = [];
    this.saveFilters = this.otherParams?.saveFilters;

    if (this.otherParams?.loadFilters) {
      const savedFilters: any = JSON.parse(
        sessionStorage.getItem('savedFilters') || '{}'
      );
      if (Object.keys(savedFilters)?.length) {
        this.appliedFilters = savedFilters?.appliedFilters;
        this.desktopTableDetails.forEach((item: any) => {
          savedFilters?.desktopTableDetails?.forEach((column: any) => {
            if (item?.name == column?.name) {
              item.sortOrder = column?.sortOrder;
            }
          });
        });
      }

      sessionStorage.removeItem('savedFilters');
    }

    this.applyPagination();

    this.apiFiltering = this.desktopTableBackendData ? true : false;

    this.tableData = this.desktopTableUIData || this.desktopTableBackendData;

    if (changes?.['desktopTableDetails']) {
      const initialDateRangeFilterCol = this.desktopTableDetails?.find(
        (col: any) => col?.dateRange
      );
      if (initialDateRangeFilterCol) {
        this.appliedFilters = [];
        this.addDateRangeToList(
          initialDateRangeFilterCol.dateRange,
          initialDateRangeFilterCol
        );
      }
    }
    if (!this.apiFiltering) {
      this.applyFilters();
    }
  }
  applyPagination() {
    this.totalRows =
      this.otherParams?.paginationData?.totalRows || this.totalRows;
    if (this.apiFiltering) {
      this.previousRows = this.otherParams?.paginationData?.previousRows;
    } else {
      this.previousRows =
        this.otherParams?.paginationData?.previousRows || this.previousRows;
    }

    this.rows = this.otherParams?.paginationData?.rows || this.rows;
    this.pageNum = this.otherParams?.paginationData?.pageNum || this.pageNum;
    this.totalPages =
      this.otherParams?.paginationData?.totalPages || this.totalPages;
  }
  addDateRangeToList(event: any, column?: any) {
    let dateKey: any;
    let rangeField: any;
    if (column) {
      dateKey = column?.header ? column?.header : column?.name;
      rangeField = column?.dateRangeKey ? column?.dateRangeKey : column?.name;
      column.dateRange = event;
    } else {
      let filteredItems = this.desktopTableDetails?.filter(
        (item: any) => item?.datetimeObj == true
      );
      let keyValue =
        filteredItems?.find((item: any) => item?.default) || filteredItems[0];
      dateKey = keyValue?.header ? keyValue?.header : keyValue?.name;
      rangeField = keyValue?.dateRangeKey
        ? keyValue?.dateRangeKey
        : keyValue?.name;
    }

    if (dateKey) {
      this.appliedFilters = this.appliedFilters?.filter(
        (item: any) => item?.key != dateKey
      );
      this.appliedFilters.push({
        key: dateKey,
        value: `[${String(
          formatDateTimeStamp(event?.start, 'd MMM y HH:mm', 'en_US')
        )}] to [${String(
          formatDateTimeStamp(event?.end, 'd MMM y HH:mm', 'en_US')
        )}]`,
        date: event,
        datetimeObj: true,
        field: rangeField,
      });
    }
  }
  onActionClick(event: any, type: any) {
    this.actionClick.emit({ event, type });
  }

  onRowClick(rowData: any) {
    this.rowClick.emit(rowData);
  }

  getSearchParams() {
    let searchParams: any = {};
    let body: any = {};
    this.appliedFilters.forEach((element: any) => {
      if (element?.datetimeObj) {
        searchParams[
          `${element?.field ? element.field : 'updated_at'}__range`
        ] = [element?.date?.start, element?.date?.end];
        body.date = element?.date;
      } else if (element?.column?.dropdownKey) {
        searchParams[element.column.dropdownKey] = element?.valueId;
      } else {
        searchParams[
          `${
            element?.column?.searchKey
              ? element.column.searchKey
              : element?.column?.name
          }__icontains`
        ] = element?.value;
      }
      body = { ...body, searchParams: searchParams };
    });
    return body;
  }

  sortField(column: any) {
    // Simplify the sortOrder assignment
    column['sortOrder'] = column?.sortOrder === 'asc' ? 'desc' : 'asc';

    // Reset sortOrder for other columns
    this.desktopTableDetails.forEach((item: any) => {
      if (item?.name !== column?.name) {
        item.sortOrder = null;
      }
    });
    if (this.saveFilters) {
      this.saveFilteringDetails();
    }

    // Check if API filtering is enabled
    if (this.apiFiltering) {
      let sortKeyValue: any = column?.sortKey || column?.name;
      let sortKey: any =
        column?.sortOrder == 'desc' ? `-${sortKeyValue}` : sortKeyValue;
      let body = this.getSearchParams();

      body.sortColumn = column;
      if (sortKey) body.sortList = [sortKey];
      this.sortColumn.emit(body);
    } else {
      this.applyFilters();
      if (column?.sortOrder) {
        let sortKey = column?.sortKey || column?.name;
        if (column?.datetimeObj) {
          this.desktopTableUIData.sort((a: any, b: any) => {
            let dateA = new Date(a[sortKey]).getTime();
            let dateB = new Date(b[sortKey]).getTime();
            return column?.sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
          });
        } else {
          this.desktopTableUIData = this.sortArrayOfObject(
            this.desktopTableUIData,
            sortKey,
            column?.sortOrder
          );
        }
        this.resetTableData();
      }
    }
  }
  searchField(column: any) {
    let colKey = column?.header ? column?.header : column?.name;

    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key != colKey
    );
    this.appliedFilters.push({
      key: colKey,
      value: column?.searchValue,
      column: column,
    });
    this.applyFilters();

    column.searchValue = null;
    column.openSearchBox = false;
  }

  onTableRowClick(rowData: any, index: any) {
    this.selectedIndex = index;

    this.rowClick.emit(rowData);
  }
  handleDateRange(event: any, column?: any) {
    if (event?.end) {
      this.addDateRangeToList(event, column);
      this.applyFilters();
    }
  }

  removeFilter(keyValue: any) {
    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key !== keyValue
    );
    this.applyFilters();
  }
  applyFilters() {
    if (this.saveFilters) {
      this.saveFilteringDetails();
    }
    if (this.apiFiltering) {
      this.searchColumn.emit(
        this.appliedFilters?.length ? this.getSearchParams() : {}
      );
    } else {
      if (this.appliedFilters?.length) {
        this.filterTableData();
        this.desktopTableDataTemp = this.tableData;
        if (this.otherParams?.paginationData) {
          this.totalRows = this.desktopTableDataTemp?.length;
          this.totalPages = Math.ceil(this.totalRows / this.rows);
          this.tableData = this.desktopTableDataTemp?.slice(0, this.rows);
        }
      } else {
        this.resetTableData();
      }
    }
  }

  resetTableData() {
    this.tableData = this.desktopTableUIData;
    if (this.otherParams?.paginationData) {
      this.totalRows = this.tableData?.length;
      this.totalPages = Math.ceil(this.totalRows / this.rows);
      this.tableData = this.tableData?.slice(0, this.rows);
    }
  }

  filterTableData() {
    this.appliedFilters.forEach((element: any) => {
      this.tableData = this.desktopTableUIData?.filter((item: any) =>
        element?.datetimeObj
          ? this.filterByDate(item, element)
          : this.filterByValue(item, element)
      );
    });
  }

  filterByDate(item: any, element: any) {
    let elementField = element?.field ? element?.field : 'updated_at';
    let itemTime = new Date(item?.[elementField]).getTime();
    return (
      itemTime >= element?.date?.start.getTime() &&
      itemTime <= element?.date?.end.getTime()
    );
  }

  filterByValue(item: any, element: any) {
    return item?.[element?.column?.name]
      ?.toLowerCase()
      .includes(element?.value?.toLowerCase());
  }

  sortArrayOfObject(arr: any, key: any, sort_order = 'asc') {
    const order = sort_order.trim().toLowerCase();
    return arr.sort((a: any, b: any) => {
      if (a[key] === null) return order === 'asc' ? 1 : -1;
      if (b[key] === null) return order === 'asc' ? -1 : 1;
      if (a[key] > b[key]) {
        return order === 'asc' ? 1 : -1;
      } else if (a[key] < b[key]) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }
  getTableRowStyle(data: any, i: number) {
    return i === this.selectedIndex
      ? {
          '--bs-table-bg': 'var(--bs-gray)',
          '--bs-table-color': 'var(--bs-dark)',
          'cursor': 'pointer',
        }
      : {
          cursor: 'pointer',
          ...(this.otherParams?.tableRowStyle
            ? this.otherParams?.tableRowStyle(data)
            : {}),
        };
  }
  selectStatus(event: any, column: any) {
    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key != column?.name
    );

    this.appliedFilters.push({
      key: column?.name,
      value: event?.name,
      valueId: event?.id,
      column: column,
    });

    this.applyFilters();

    column.opendropDownBox = false;
    if (this.saveFilters) {
      this.saveFilteringDetails();
    }
  }
  openRangePicker(column: any) {
    this.dialogeRef = this.dialogService.open(DatetimePickerComponent, {
      data: { dateRange: column?.dateRange },
    });
    this.dialogeRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.handleDateRange(value, column);
      }
    });
  }

  onChangePagination(event: any) {
    this.previousRows = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    if (this.apiFiltering) {
      this.paginationChange.emit({
        paginationData: {
          totalRows: this.totalRows,
          previousRows: this.previousRows,
          rows: this.rows,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        },
      });
    } else {
      if (this.appliedFilters?.length) {
        this.tableData = this.desktopTableDataTemp?.slice(
          this.previousRows,
          this.rows * this.pageNum
        );
      } else {
        this.tableData = this.desktopTableUIData?.slice(
          this.previousRows,
          this.rows * this.pageNum
        );
      }
    }
  }
  saveFilteringDetails() {
    sessionStorage.setItem(
      'savedFilters',
      JSON.stringify({
        appliedFilters: this.appliedFilters,
        desktopTableDetails: this.desktopTableDetails,
      })
    );
  }
}
