<div class="card dialog-box">
  <div class="card-header">
    Source
    <span (click)="onDismis(false)" class="float-end">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <div class="mt-3">
    <button class="btn btn-primary w-100 btn-xl" (click)="onDismis('device')">
      <div class="d-flex align-items-center justify-content-center">
        <span>
          <i
            class="fa-solid fa-image fa-3x"
            style="color: var(--color-basic-300)"
          ></i>
        </span>

        <span class="ms-3">Library</span>
      </div>
    </button>
  </div>
  <div class="mt-3">
    <button class="btn btn-primary w-100 btn-xl" (click)="onDismis('live')">
      <div class="d-flex align-items-center justify-content-center">
        <span>
          <i
            class="fa fa-camera fa-3x"
            style="color: var(--color-basic-300)"
          ></i>
        </span>
        <span class="ms-3">Camera</span>
      </div>
    </button>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button class="btn btn-dark btn-lg w-100" (click)="onDismis(false)">
        Cancel
      </button>
    </div>
  </div>
</div>
