import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { DIALOG_DATA } from '../../../../global.variable';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrl: './image-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() imageSrc: any;
  @Input() timeStamp: any;

  previewAndCloseOnImage: boolean = false;
  zoomPercentage: any;

  @ViewChild('img', { static: true })
  imageElement!: ElementRef<HTMLImageElement>;
  @ViewChild('zoomLevel', { static: true })
  zoomLevelElement!: ElementRef<HTMLDivElement>;

  currentScale: number = 1;
  lastDistance: number = 0;
  maxScale: number = 3;
  minScale: number = 1;
  isPanning: boolean = false;
  startX: number = 0;
  startY: number = 0;
  translateX: number = 0;
  translateY: number = 0;
  constructor(
    private spinnerService: LoadingSpinnerService,
    @Optional() protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogueData: any
  ) {}
  ngOnInit(): void {
    if (this.dialogueData?.imageSrc) {
      this.imageSrc = this.dialogueData?.imageSrc;
      this.previewAndCloseOnImage = true;
      this.timeStamp = this.dialogueData?.timeStamp;
    }
  }
  ngAfterViewInit(): void {
    if (this.previewAndCloseOnImage) {
      let overlayContainer: any = document.querySelector(
        '.cdk-overlay-container'
      );
      if (overlayContainer) {
        overlayContainer.style['z-index'] = '1004';
        const overlayPanel = overlayContainer.querySelector('.overlay-panel');
        if (overlayPanel) {
          // Apply styles to make it fullscreen and position at the top
          Object.assign(overlayPanel['style'], {
            'width': '100%',
            'height': '100%',
            'top': '0',
            'left': '0',
            'transform': 'none',
            'position': 'fixed',
            'zIndex': '1000',
            'background-color': 'var(--bs-dark)',
          });
        }
      }
      setTimeout(() => {
        document.body.classList.add('no-scroll');
      }, 1);
      const image = this.imageElement.nativeElement;

      // Add event listener for mouse wheel (desktop)
      image.addEventListener('wheel', (event: WheelEvent) => {
        event.preventDefault();
        this.zoomImage(
          event.deltaY < 0 ? 1.1 : 0.9,
          event.clientX,
          event.clientY
        );
      });

      // Add event listener for touch (mobile)
      image.addEventListener('touchmove', (event: TouchEvent) => {
        if (event.touches.length === 2) {
          const newDistance = this.getDistance(event.touches);
          if (this.lastDistance) {
            const scale = newDistance / this.lastDistance;
            this.zoomImage(
              scale,
              event.touches[0].clientX,
              event.touches[0].clientY
            );
          }
          this.lastDistance = newDistance;
        }
      });

      image.addEventListener('touchend', () => {
        this.lastDistance = 0; // Reset the distance when touch ends
      });
      image.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        const scaleFactor =
          this.currentScale >= this.maxScale
            ? this.minScale / this.currentScale
            : 1.5;
        this.zoomImage(scaleFactor, event.clientX, event.clientY);
      });
      // Add event listeners for panning
      image.addEventListener('mousedown', (event: MouseEvent) =>
        this.startPan(event.clientX, event.clientY)
      );
      image.addEventListener('mousemove', (event: MouseEvent) =>
        this.pan(event.clientX, event.clientY)
      );
      image.addEventListener('mouseup', () => this.endPan());
      image.addEventListener('mouseleave', () => this.endPan());

      image.addEventListener('touchstart', (event: TouchEvent) => {
        if (event.touches.length === 1) {
          this.startPan(event.touches[0].clientX, event.touches[0].clientY);
        }
      });
      image.addEventListener('touchmove', (event: TouchEvent) => {
        if (event.touches.length === 1) {
          this.pan(event.touches[0].clientX, event.touches[0].clientY);
        }
      });
      image.addEventListener('touchend', () => this.endPan());
    }
  }
  startPan(clientX: number, clientY: number): void {
    if (this.currentScale > 1 && this.currentScale < 3) {
      // Only allow panning when zoomed in and not high level zoom
      this.isPanning = true;
      this.startX = clientX - this.translateX;
      this.startY = clientY - this.translateY;
    }
  }

  pan(clientX: number, clientY: number): void {
    if (this.isPanning) {
      const image: any = this.imageElement.nativeElement;
      const container = image.parentElement.getBoundingClientRect();

      // Get image size and current scale
      const imageRect = image.getBoundingClientRect();
      const imageWidth = imageRect.width;
      const imageHeight = imageRect.height;

      // Calculate max translation based on the image size and container size
      const maxTranslateX = Math.max(0, (imageWidth - container.width) / 2);
      const maxTranslateY = Math.max(0, (imageHeight - container.height) / 2);

      // Calculate new translation values
      this.translateX = clientX - this.startX;
      this.translateY = clientY - this.startY;

      // Constrain panning to the maximum allowed values
      this.translateX = Math.max(
        -maxTranslateX,
        Math.min(maxTranslateX, this.translateX)
      );
      this.translateY = Math.max(
        -maxTranslateY,
        Math.min(maxTranslateY, this.translateY)
      );

      // Update transform
      this.updateTransform();
    }
  }

  endPan(): void {
    this.isPanning = false;
  }

  updateTransform(): void {
    const image = this.imageElement.nativeElement;
    image.style.transform = `scale(${this.currentScale}) translate(${this.translateX}px, ${this.translateY}px)`;
  }

  zoomImage(scaleFactor: number, originX: number, originY: number): void {
    this.currentScale *= scaleFactor;

    const image = this.imageElement.nativeElement;
    const rect = image.getBoundingClientRect();
    const offsetX = (originX - rect.left) / rect.width;
    const offsetY = (originY - rect.top) / rect.height;

    image.style.transformOrigin = `${offsetX * 100}% ${offsetY * 100}%`;
    image.style.transform = `scale(${this.currentScale})`;
    this.updateZoomLevelDisplay();
  }

  getDistance(touches: TouchList): number {
    const touch1 = touches[0];
    const touch2 = touches[1];
    return Math.hypot(
      touch2.clientX - touch1.clientX,
      touch2.clientY - touch1.clientY
    );
  }
  updateZoomLevelDisplay(): void {
    const zoomLevel = Math.round(this.currentScale * 100);
    if (zoomLevel) {
      this.zoomPercentage = `${zoomLevel}%`;
    }
    this.zoomLevelElement.nativeElement.innerText = `${zoomLevel}%`;
    this.zoomLevelElement.nativeElement.style.display = 'block';
    setTimeout(() => {
      this.zoomLevelElement.nativeElement.style.display = 'none';
    }, 3000);
  }

  onCloseDialogue() {
    this.dialogRef.close(false);
  }
  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
    var ele: any = document.querySelector('.cdk-overlay-container');
    ele.style.backgroundColor = null;
    ele.style['z-index'] = '1000';
    const overlayPanel = ele.querySelector('.overlay-panel');
    if (overlayPanel) {
      Object.assign(overlayPanel['style'], {});
    }
  }
}
