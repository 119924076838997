import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-multiple-subscribers',
  templateUrl: './multiple-subscribers.component.html',
  styleUrl: './multiple-subscribers.component.scss',
})
export class MultipleSubscribersComponent implements OnInit {
  userLinkedSubscribers: any;

  constructor(
    protected dialogRef: DialogRef,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private profileService: ProfileService,
    private dialog: ModelDialogueService
  ) {
    this.getAssociatedCompanies();
  }

  ngOnInit(): void {}
  getAssociatedCompanies() {
    this.spinnerService.show();
    this.profileService
      .associatedCompanies({}, { get_companies: 1 })
      .subscribe((res: any) => {
        if (res['status'] === 'success') {
          this.spinnerService.hide();
          this.userLinkedSubscribers = res['data'];
        }
      });
  }
  onCloseDialogue() {
    this.dialogRef.close('close');
  }
  selectSubscriber(subscriber: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        // confirmation: true,
        title: `Change Account`,
        // message: `Changing subscriber means all the actions performed after this will be associated to  ${subscriber?.name} `,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        let userData = this.appService.getUserData();
        this.profileService
          .associatedCompanies({
            subscriber_id: subscriber.id,
            remember_login: userData?.token_expiry ? 1 : 0,
          })
          .subscribe((res: any) => {
            if (res['status'] === 'success') {
              this.spinnerService.show();

              localStorage.clear();

              delete res['message'];
              res.checks.fido_login = userData?.checks?.fido_login
                ? true
                : false;
              this.appService.setUserData(res);
              localStorage.setItem('loginSuccess', 'true');
              window.location.reload();
              this.spinnerService.hide();
            }
            this.spinnerService.hide();
          });
      }
    });
  }
}
