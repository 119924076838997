<div class="card dialog-box">
  <div class="card-header">
    Change Log
    <span (click)="onDismis()" class="float-end">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <div class="mt-3">
    <dl>
      <div class="fw-bold">
        v{{ latestVersion?.last_item?.version }}
        <em>
          Released
          {{ latestVersion?.last_item?.updated_at | customDate : "d MMM y" }}
        </em>
      </div>
    </dl>
    <dl>
      <dd class="gt-multi-line">
        {{ latestVersion?.last_item?.commit_messages }}
      </dd>
    </dl>
  </div>
  <hr />

  <ng-container *ngIf="latestVersion?.previous_changes?.length">
    <!--previous version updates-->
    <div
      *ngFor="let version of latestVersion?.previous_changes; let l = last"
      class="small-font"
    >
      <span class="fw-bold"
        >v{{ version?.version }}
        <em>
          Released
          {{ version?.updated_at | customDate : "d MMM y" }}</em
        ></span
      >

      <div style="white-space: pre-line" class="mt-3">
        {{ version?.commit_messages }}
      </div>
      <hr *ngIf="!l" />
    </div>
  </ng-container>
</div>
