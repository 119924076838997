<ng-container *ngIf="cursorDraggable">
  <div class="form-floating">
    <input
      class="form-control"
      fullWidth
      nbInput
      type="tel"
      [min]="minKm"
      placeholder="Geofence Radius (metres)"
      [max]="maxKm"
      [(ngModel)]="nearestKmValue"
      (input)="changeGeoFenceRadius()"
      (blur)="changeGeoFenceRadius()"
      pattern="\d*"
    /><label for="inputAssignee">Geofence Radius (metres)</label>
  </div>
  <input
    *ngIf="!mobileView"
    class="range-slider-input"
    type="range"
    style="width: 100%"
    [min]="minKm"
    [max]="maxKm"
    [(ngModel)]="nearestKmValue"
    pattern="\d*"
    placeholder="Geofence Radius (metres)"
    nbTooltip="Checkpoint can only be scanned when within this distance of address. Must be less than 1000 metres"
    nbTrigger="focus"
    fullWidth
    (ngModelChange)="onSliderChange()"
    (mouseup)="changeGeoFenceRadius()"
    (touchend)="changeGeoFenceRadius()"
  />
</ng-container>

<div
  [id]="mapId"
  class="map mt-2"
  style="position: relative; z-index: 0; max-height: 100%; max-width: 100%"
  [ngStyle]="
    mapId === 'checkpointEditmapid'
      ? { height: '40vh' }
      : mapId === 'alertMapId'
      ? { height: '30vh' }
      : {}
  "
></div>
<div class="small text-center">{{ latLon?.lat }}, {{ latLon?.lon }}</div>
