import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-upload-type',
  templateUrl: './upload-type.component.html',
  styleUrl: './upload-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadTypeComponent {
  constructor(protected dialogRef: DialogRef) {}

  ngOnInit(): void {}
  onDismis(close: any) {
    this.dialogRef.close(close);
  }
}
