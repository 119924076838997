<div class="card dialog-box">
  <div class="card-header">
    {{ editSite ? "Edit Site Details" : "Add New Site" }}
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div>
    <!-- Forms for Site Details -->
    <form *ngIf="!editSite" [formGroup]="siteForm">
      <!-- Stepper 1 -->

      <div>
        <div class="row row-cols-1 g-2">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="site_name"
              formControlName="company_name"
              placeholder="Site Name"
              autocomplete="off"
            />
            <label for="company_name">Site Name</label>
          </div>
          <div>
            <gtapp-auto-complete
              [data]="addrLookupData"
              placeHolder="Site Address"
              searchKeyword="full_address"
              [initialValue]="siteAddressValue"
              [itemTemplate]="addressTemplate"
              [addInitialKey]="true"
              (inputChanged)="onAddressSearch($event)"
              (selected)="addressSelected($event)"
            >
            </gtapp-auto-complete>
          </div>

          <ng-template #addressTemplate let-item>
            @if (item?.key === 0) {
            <div>Enter address manually</div>
            } @else {
            <div class="small fw-semibold">
              {{ item.full_address }}
            </div>
            }
          </ng-template>

          <gtapp-address-lookup
            [addrLookupData]="addrLookupData"
            [siteForm]="siteForm"
            [addressValue]="addressValue"
            (emitChangesData)="editAddressLookup($event)"
          >
          </gtapp-address-lookup>

          <div *ngIf="showFrom === 'addJob'">
            <div>
              <gtapp-auto-complete
                [data]="customerList"
                placeHolder="Client"
                searchKeyword="company_name"
                [initialValue]="clientValue"
                [itemTemplate]="ClientTemplate"
                [addInitialKey]="true"
                (inputChanged)="onClientSearch($event)"
                (selected)="onClientSelect($event)"
              >
              </gtapp-auto-complete>
            </div>

            <ng-template #ClientTemplate let-item>
              @if (item?.key === 0) {
              <div>Add New Client</div>
              } @else {
              <div>
                <div>{{ item?.company_name }}</div>
                <div>
                  <small>{{ item?.address?.full_address }}</small>
                </div>
              </div>
              }
            </ng-template>
          </div>
          <div class="row row-cols-2 mt-2">
            <div class="col">
              <div class="col">
                <button
                  class="btn btn-dark btn-lg w-100"
                  (click)="onCloseDialogue()"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div class="col">
              <button
                type="submit"
                class="btn btn-primary btn-lg w-100"
                id="submitbtn1"
                (click)="updateData()"
                [disabled]="!siteForm.valid || !selectedClient"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form *ngIf="editSite" [formGroup]="siteForm">
      <ng-container *ngIf="currentEditSiteStep === 1 && siteName">
        <div class="form-floating">
          <input
            class="form-control"
            formControlName="company_name"
            type="text"
            placeholder="Site Name"
            id="siteName"
          />
          <label for="siteName">Site Name</label>
        </div>
        <div class="row row-cols-2 mt-2">
          <div class="col">
            <button
              class="btn btn-dark btn-lg btn-block w-100"
              (click)="onCloseDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              id="submitbtn"
              (click)="updateData()"
              class="btn btn-primary btn-lg w-100"
            >
              Submit
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentEditSiteStep === 2 && !siteName">
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="addrLookupData"
            placeHolder="Site Address"
            searchKeyword="full_address"
            [initialValue]="siteAddressValue"
            [itemTemplate]="addressTemplate"
            [addInitialKey]="true"
            (inputChanged)="onAddressSearch($event)"
            (selected)="addressSelected($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #addressTemplate let-item>
          @if (item?.key === 0) {
          <div>Enter address manually</div>
          } @else {
          <div class="small fw-semibold">
            {{ item.full_address }}
          </div>
          }
        </ng-template>
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="
            siteForm.controls['address1'].invalid ||
            siteForm.controls['address2'].invalid ||
            siteForm.controls['postcode'].invalid ||
            siteForm.controls['city_name'].invalid
          "
        >
          Unable to get exact address, please fill in missing information
        </div>
        <div class="row g-3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="address1"
              formControlName="address1"
              maxlength="30"
              placeholder="Street Number"
            />
            <label for="address1">Street Number</label>
          </div>

          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="address2"
              formControlName="address2"
              maxlength="100"
              autocomplete="off"
              placeholder="Street"
            />
            <label for="address2">Street Name</label>
          </div>

          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="address3"
              formControlName="address3"
              maxlength="100"
              autocomplete="off"
              placeholder="Unit"
            />
            <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
          </div>

          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="city_name"
              formControlName="city_name"
              maxlength="100"
              autocomplete="off"
              placeholder="City"
              (input)="forceUppercaseConditionally($event)"
              style="text-transform: uppercase"
            />
            <label for="city_name">Suburb</label>
          </div>

          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="postcode"
              formControlName="postcode"
              maxlength="30"
              autocomplete="off"
              placeholder="Post Code"
            />
            <label for="postcode">Post Code</label>
          </div>

          <div class="form-floating">
            <select
              class="form-select"
              id="state_code"
              formControlName="state_code"
              placeholder="State"
            >
              <option *ngFor="let state of stateData" [value]="state.code">
                {{ state.code }}
              </option>
            </select>
            <label for="state_code">State</label>
          </div>
        </div>
        <div class="row row-cols-2 mt-2">
          <div class="col">
            <button
              class="btn btn-dark btn-lg btn-block w-100"
              (click)="onCloseDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              id="submitbtn"
              (click)="updateData()"
              class="btn btn-primary btn-lg w-100"
            >
              Submit
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
