<div class="card dialog-box">
  <!-- Input for live photos from users -->
  <input
    id="fileInputLive"
    class="form-control-file"
    style="display: none; cursor: pointer"
    type="file"
    accept="image/*"
    capture="environment"
    (change)="handleFileInput($event.target)"
  />

  <!-- Input for photos uploaded from device -->
  <input
    id="fileInput"
    class="form-control-file"
    style="display: none; cursor: pointer"
    type="file"
    accept=".jpg, .jpeg, .png,.avif"
    (change)="handleFileInput($event.target)"
  />
  @if(isCameraOpen && !actualUploadFile && !imageUrl ){

  <div class="row row-cols-2">
    <div class="col">
      <button class="btn btn-dark btn-lg w-100" (click)="close()">
        Cancel
      </button>
    </div>

    <div class="col">
      <button
        class="btn btn-primary btn-lg w-100"
        (click)="selectDeviceUploadType(); imageUrl = null"
      >
        Retry
      </button>
    </div>
  </div>
  }

  <!-- Image display section -->
  <ng-container
    *ngIf="(webcamImage || imageUrl) && showFrom !== 'updateClient'"
  >
    <gtapp-image-preview [imageSrc]="webcamImage ? webcamImage : imageUrl">
    </gtapp-image-preview>
    <div class="form-group mt-3" *ngIf="showFrom != 'userProfilePage'">
      <textarea
        rows="2"
        class="form-control"
        maxlength="300"
        placeholder="Add a Comment..."
        [(ngModel)]="newComment"
      ></textarea>
    </div>
    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button class="btn btn-dark btn-lg w-100" (click)="close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          (click)="onFileSubmit()"
          [disabled]="!imageUrl && !webcamImage"
          class="btn btn-primary btn-lg w-100"
        >
          {{ btnLable }}
        </button>
      </div>
    </div>
  </ng-container>

  <!-- Section for blocking app interaction during client update -->
  <ng-container *ngIf="(webcamImage || imageUrl) && showFrom == 'updateClient'">
    <!-- No clients found message -->
    <div *ngIf="!apidataUpdation && clientCount === 0" class="empty-add-photo">
      <div class="alert alert-warning">No clients found</div>
      <div class="col">
        <button class="btn btn-dark btn-lg w-100" (click)="close()">
           Back
        </button>
      </div>
    </div>

    <div *ngIf="apidataUpdation || (!apidataUpdation && clientCount > 0)">
      <gtapp-image-preview [imageSrc]="webcamImage ? webcamImage : imageUrl">
      </gtapp-image-preview>
      <div class="form-group mt-3">
        <textarea
          class="form-control"
          rows="2"
          maxlength="300"
          placeholder="Add a Comment..."
          [disabled]="!apidataUpdation && clientCount === 0 ? true : false"
          [(ngModel)]="newComment"
        ></textarea>
      </div>

      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button class="btn btn-dark btn-lg w-100" (click)="close()">
            Cancel
          </button>
        </div>
        <div class="col">
          <button (click)="onSavePhoto()" class="btn btn-primary btn-lg w-100">
            Save
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
