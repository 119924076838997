<div *ngIf="!checkPointForm.value?.qr_text" class="dialog-box card">
  <ng-container *ngIf="!cameraFeedDetected">
    <div class="alert alert-primary mt-5">
      <div>
        There was a problem getting your camera feed, please try the
        following:<br />
        1. Check your browser permissions and ensure camera access is
        allowed.<br />
        2. Try running Guard Tracker in a new browser tab.<br />
        3. Power off your device, wait 5 seconds, power on and try again.
      </div>
    </div>
  </ng-container>
  <div class="col text-end" *ngIf="cameraDevices?.length > 1">
    <button class="btn btn-secondary my-1" (click)="cycleCamera()">
      <i class="fa-solid fa-camera-rotate"></i>
    </button>
  </div>

  <div id="html5-qr-reader" class="html5-qr-reader mb-2"></div>
  <div *ngIf="zoomRange?.max && zoomRange?.min">
    <label class="mx-2"> Zoom</label>
    <label class="float-end mx-2"> {{ zoomRange.value }} x</label>
    <input
      style="width: 100%"
      type="range"
      [min]="zoomRange?.min"
      placeholder="Zoom"
      [max]="zoomRange?.max"
      [(ngModel)]="zoomRange.value"
      (mouseup)="applyZoom()"
      (touchend)="applyZoom()"
    />
  </div>
  <div [ngClass]="html5torch ? 'row row-cols-2 mt-2' : 'row row-cols-1 mt-2'">
    <div class="col">
      <button class="btn btn-danger btn-lg w-100" (click)="onCloseDialogue()">
        Cancel
      </button>
    </div>
    <div class="col" *ngIf="html5torch">
      <button
        class="btn btn-primary btn-lg w-100"
        (click)="turnOffOnTorchHTML5(devieTorchOn ? 'off' : 'on')"
      >
        {{ devieTorchOn ? "Turn off Torch" : "Turn on Torch" }}
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="checkPointForm.value?.qr_text && showForm">
  <div class="card dialog-box">
    <div class="card-header">
      <div>
        {{ checkPointScanData?.id ? "Scan Checkpoint" : "Register QR Code" }}
        <span class="float-end" (click)="onCloseDialogue()">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div *ngIf="checkPointForm.value?.qr_text" class="text-center">
        <span class="uppercase fw-bold">
          {{
            checkPointForm.value?.qr_text.length == 44
              ? checkPointForm.value?.qr_text.slice(32)
              : checkPointForm.value?.qr_text
          }}</span
        >
      </div>
    </div>
    <!--register QR html code-->

    <!-- register an existing QR to a new checkpoint-->
    <div class="mt-3" *ngIf="checkPointForm.value?.qr_text && showForm">
      <div *ngIf="offlineMode">
        <div class="alert alert-danger">
          NO INTERENT CONNECTIVITY: Some features will not be avaliable
        </div>
      </div>
      <form [formGroup]="checkPointForm">
        <ng-container *ngIf="currentStep === 1">
          <div class="row row-cols-1 g-2">
            <div class="form-floating">
              <input
                class="form-control"
                formControlName="name"
                [maxlength]="100"
                type="text"
                placeholder="Checkpoint Name"
              />
              <label for="inputService">Checkpoint Name</label>
            </div>

            <div>
              <gtapp-auto-complete
                [data]="customerListData"
                placeHolder="Search Clients"
                searchKeyword="company_name"
                [itemTemplate]="clientLookupTempalte"
                (inputChanged)="onClientSearch($event)"
                (selected)="onClientSelect($event)"
              >
              </gtapp-auto-complete>
            </div>

            <ng-template #clientLookupTempalte let-item>
              <div class="fw-bold">
                {{ item?.company_name }}
              </div>
              <div class="small">
                {{ item?.address?.full_address }}
              </div>
            </ng-template>

            <ng-container *ngIf="isAdmin">
              <div class="form-floating">
                <input
                  class="form-control"
                  fullWidth
                  id="description"
                  formControlName="min_distance"
                  nbInput
                  type="tel"
                  [min]="minKm"
                  placeholder="Geofence Radius (metres)"
                  [max]="maxKm"
                  [(ngModel)]="nearestKmValue"
                  pattern="\d*"
                /><label for="inputAssignee">Geofence Radius (metres)</label>
              </div>
              <span>
                <input
                  class="range-slider-input w-100"
                  type="range"
                  [min]="minKm"
                  placeholder="Geofence Radius (metres)"
                  [max]="maxKm"
                  [(ngModel)]="nearestKmValue"
                  formControlName="min_distance"
                />
              </span>
            </ng-container>

            <div class="form-floating">
              <input
                class="form-control"
                id="description"
                formControlName="description"
                type="text"
                placeholder="Description (Optional)"
              />
              <label for="inputAssignee">Description (Optional)</label>
            </div>

            <div class="mt-4">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="registerScan"
                  (change)="registerScan = !registerScan"
                  [checked]="registerScan"
                />
                <label class="form-check-label" for="registerScan"
                  >Scan Checkpoint After Adding</label
                >
                <span
                  class="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="When ticked, checkpoint can only be scanned at checkpoint location."
                >
                  <i class="fa-solid fa-circle-info"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="row row-cols-2 mt-3">
            <div class="col">
              <button
                type="button"
                (click)="onCloseDialogue()"
                class="btn btn-dark btn-lg w-100"
              >
                Cancel
              </button>
            </div>
            <div
              class="col"
              *ngIf="
                checkPointForm.value.is_locked && (isAdmin || isDispatchUser)
              "
            >
              <button
                type="button"
                class="btn btn-primary btn-lg w-100"
                [disabled]="!checkPointForm.valid"
                (click)="showMapMethod(); currentStep = 2"
              >
                Select Location
              </button>
            </div>
            <div class="col" *ngIf="!checkPointForm.value.is_locked">
              <button
                type="submit"
                [disabled]="!checkPointForm.valid"
                class="btn btn-primary btn-lg w-100"
                (click)="onClickSubmit()"
              >
                Add Checkpoint
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentStep === 2">
          <div class="row row-cols-2 my-2">
            <div class="col">
              <button
                (click)="currentStep = 1"
                class="btn btn-dark btn-lg w-100"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                [disabled]="!checkPointForm.valid"
                class="btn btn-primary btn-lg w-100"
                (click)="onClickSubmit()"
              >
                Add Checkpoint
              </button>
            </div>
          </div>
          <div>
            <div class="form-floating">
              <input
                class="form-control"
                fullWidth
                id="description"
                formControlName="min_distance"
                nbInput
                type="tel"
                [min]="minKm"
                placeholder="Geofence Radius (metres)"
                [max]="maxKm"
                [(ngModel)]="nearestKmValue"
                pattern="\d*"
              /><label for="inputAssignee">Geofence Radius (metres)</label>
            </div>
            <input
              class="range-slider-input"
              type="range"
              style="width: 100%"
              [min]="minKm"
              [max]="maxKm"
              [(ngModel)]="nearestKmValue"
              pattern="\d*"
              placeholder="Geofence Radius (metres)"
              nbTooltip="Checkpoint can only be scanned when within this distance of address. Must be less than 1000 metres"
              nbTrigger="focus"
              fullWidth
              formControlName="min_distance"
            />
          </div>
          <div *ngIf="showMap && gps">
            <div class="form-floating">
              <div>
                <gtapp-auto-complete
                  [data]="addrLookupData"
                  placeHolder="Address"
                  searchKeyword="full_address"
                  [itemTemplate]="autoNgModelEditCheckpoint"
                  (inputChanged)="onAddressSearch($event)"
                  (selected)="addressSelected($event)"
                >
                </gtapp-auto-complete>
              </div>

              <ng-template #autoNgModelEditCheckpoint let-item>
                @if (item?.key === 0) {
                <div>Enter address manually</div>
                } @else {
                <div>
                  <span
                    ><b>{{ item.address1 + " " + item.address2 }}</b
                    >,</span
                  ><br />
                  <small
                    >{{ item.address3 ? item.address3 + " " : "" }}
                    {{ item.city_name }} {{ item.state_name }}
                    {{ item.postcode }}</small
                  >
                </div>
                }
              </ng-template>
            </div>

            <gtapp-map-leaflet
              [latLon]="gps"
              [mapId]="'checkpointEditmapid'"
              [cursorDraggable]="true"
              (emitData)="emitData($event, true)"
            ></gtapp-map-leaflet>
            <div
              *ngIf="
                (checkPointForm.value?.latitude &&
                  checkPointForm.value?.longitude) ||
                (gps.lat && gps.lon)
              "
              style="
                text-align: center;
                text-transform: uppercase;
                font-size: 0.7rem;
              "
            >
              <div>Drag the marker to set coordinates</div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</ng-container>
