import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { DeviceInfoService } from '../../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-save-new-address',
  templateUrl: './save-new-address.component.html',
  styleUrl: './save-new-address.component.scss',
})
export class SaveNewAddressComponent implements OnInit {
  addressForm: UntypedFormGroup = new UntypedFormGroup({
    addressLookup: new UntypedFormControl(''),
    address1: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl('', Validators.max(100)),
    city_name: new UntypedFormControl('', [Validators.required]),
    state_code: new UntypedFormControl('', [Validators.required]),
    country_name: new UntypedFormControl('Australia', Validators.max(50)),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
  });
  stateData: any;
  @Input() latLon: any;
  @Input() showFrom: any;
  linearMode: any;
  mapForm: any;
  addressObjectValue: any;
  selectedLatlon: any;
  newAddress: any;
  showMap: boolean = true;
  thirdPartyAddresses: any = [];
  selectedThirdPartyAddress: any = {};
  selectedThirdPartyFullAddress: string = '';
  currentStep: number = 1;

  constructor(
    private appService: AppService,
    private deviceInfoService: DeviceInfoService,
    private spinnerService: LoadingSpinnerService,
    @Optional() protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogueData: any
  ) {}

  ngOnInit(): void {
    if (this.dialogueData) {
      this.latLon = this.dialogueData?.latLon;
      this.showFrom = this.dialogueData?.showFrom;
    }
    this.getStates(1);
    if (this.latLon) this.selectedLatlon = this.latLon;
  }

  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }
  getStateCode(stateCode: any) {
    let codeList = this.stateData?.filter(
      (state: any) => state?.code?.toLowerCase() === stateCode?.toLowerCase()
    );
    return codeList?.length == 1 ? stateCode?.toUpperCase() : null;
  }

  onCloseDialogue(val = 'submit') {
    this.dialogRef.close(false);
  }

  updateData() {
    this.dialogRef.close(this.addressForm.value);
  }
  findAddress() {
    this.spinnerService.show();
    if (
      this.selectedThirdPartyAddress?.coordinates?.latitude ===
        this.selectedLatlon?.lat &&
      this.selectedThirdPartyAddress?.coordinates?.longitude ===
        this.selectedLatlon?.lon
    ) {
      this.addressForm.patchValue({
        address1:
          this.selectedThirdPartyAddress?.context?.address?.address_number,
        address2:
          this.selectedThirdPartyAddress?.context?.street?.name?.toUpperCase(),
        address3: null,
        city_name:
          this.selectedThirdPartyAddress?.context?.neighborhood?.name?.toUpperCase() ||
          this.selectedThirdPartyAddress?.context?.locality?.name?.toUpperCase() ||
          this.selectedThirdPartyAddress?.context?.place?.name?.toUpperCase(),
        state_code: this.getStateCode(
          this.selectedThirdPartyAddress?.context?.region?.region_code
        ),
        longitude: this.selectedLatlon?.lon,
        latitude: this.selectedLatlon?.lat,
        postcode: this.selectedThirdPartyAddress?.context?.postcode?.name,
      });
      this.spinnerService.hide();
      if (this.showFrom !== 'cpPage') {
        this.dialogRef.close(this.addressForm.value);
      }
    } else {
      this.spinnerService.show();
      this.appService
        .getReverseLocation(this.selectedLatlon?.lat, this.selectedLatlon?.lon)
        .subscribe((response: any) => {
          this.newAddress = response;

          let addressStreet = response?.address?.Address.split(' ');
          let streetName = [
            ...addressStreet.filter(
              (item: any) => item != response?.address?.AddNum
            ),
          ].join(' ');

          this.addressForm.patchValue({
            address1: response?.address?.AddNum,
            address2: streetName?.toUpperCase(),
            address3: null,
            city_name: response?.address?.Neighborhood
              ? response?.address?.Neighborhood?.toUpperCase()
              : response?.address?.City?.toUpperCase(),
            state_code: this.getStateCode(response?.address?.RegionAbbr),
            longitude: this.selectedLatlon?.lon,
            latitude: this.selectedLatlon?.lat,
            postcode: response?.address?.Postal,
          });
          this.spinnerService.hide();
          if (this.showFrom !== 'cpPage') {
            this.dialogRef.close(this.addressForm.value);
          }
        });
    }
  }
  getLatLon(event: any) {
    this.selectedLatlon = { lat: event?.lat, lon: event?.lng };
  }
  showHideMap() {
    this.selectedLatlon = null;
    this.latLon = null;
    this.spinnerService.show();
    this.showMap = false;
    setTimeout(() => {
      this.showMap = true;
    }, 10);

    this.spinnerService.hide();
  }
  async useCurrentLocation() {
    this.spinnerService.show();
    this.latLon = await this.deviceInfoService.getGpsCoordinates();
    this.selectedLatlon = this.latLon;
    this.showMap = false;
    setTimeout(() => {
      this.showMap = true;
      this.spinnerService.hide();
    }, 10);
  }
  forceUppercaseConditionally(event: any) {
    this.addressForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }
  thridPartySearchAddress(event: any) {
    if (event.target.value?.length > 4) {
      this.appService
        .addressLookupShared(event.target.value, { search_3rd_party: 1 })
        .subscribe((res: any) => {
          this.thirdPartyAddresses = res?.data;
        });
    }
  }
  thirdPartyAddressSelected(address: any) {
    if (address.hasOwnProperty('properties')) {
      this.spinnerService.show();

      this.selectedThirdPartyAddress = address?.properties;
      this.selectedThirdPartyFullAddress =
        this.selectedThirdPartyAddress?.full_address;

      this.latLon = {
        lat: this.selectedThirdPartyAddress?.coordinates?.latitude,
        lon: this.selectedThirdPartyAddress?.coordinates?.longitude,
      };
      this.selectedLatlon = this.latLon;

      this.showMap = false;
      setTimeout(() => {
        this.showMap = true;
      }, 10);

      this.spinnerService.hide();
    }
  }
}
