import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';
import { DataCheckService } from '../../shared/services/data-check.service';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  // this key will be used to check whther the user
  // is in the middle of updating job/client or scan checkpoints
  isOnEvent = new Subject();
  // this key will be used to pass data to pages component from child component about service worker related operations
  sWOperations = new Subject();

  // use this for all the needs, no need to create multiple subjects instead use this one and pass dictionary values
  // {
  // this key will be used to pass the updated client count to
  // camera component, so that the user can cancel the upload
  //if there are no clients near him after he takes an image
  //   addPhotoClientCount: value;
  //   sosAlertActive: value;
  // }
  miscSubjectParam = new Subject();

  constructor(
    private http: HttpClient,
    private dataCheckService: DataCheckService
  ) {}

  refreshTokenApi(isSuperUser: number = 0) {
    return this.http.get('api/refresh_token/', {
      params: { is_super_user: isSuperUser },
    });
  }
  sendToSW(data: any) {
    // TODO: use dexie instead of this
    let payload: any = { type: 'userData' };
    Object.keys(data).forEach((key) => {
      if (['access', 'profile', 'subscriber'].includes(key)) {
        payload[key] = data[key];
      }
    });

    navigator?.serviceWorker?.controller?.postMessage(payload);
  }
  async refreshToken() {
    // check whether token_expiry key is present in localStorage if so, refresh it 5 day before it expires and update localStorage
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const tokenExpiry = userData?.token_expiry;

    if (!tokenExpiry) return false;

    const isSuperUser = this.dataCheckService.isSuperUser() ? 1 : 0;
    const diffDays = this.calculateDateDifference(tokenExpiry);

    if (diffDays > 5) return false;

    const response = await lastValueFrom(this.refreshTokenApi(isSuperUser));
    localStorage.setItem('userData', JSON.stringify(response));
    this.sendToSW(response);

    return response;
  }

  calculateDateDifference(tokenExpiry: any) {
    const date1 = new Date(tokenExpiry);
    const date2 = new Date();

    return Math.floor(
      (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
    );
  }
}
