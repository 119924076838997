<div class="card dialog-box">
  <div class="card-header">
    {{ updateView ? "Edit Client Details" : "Add New Client" }}
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="clientForm" appFormEnterAction>
    <div class="step" id="contact-step" *ngIf="currentStep === 1">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="company_name"
          formControlName="company_name"
          autocomplete="off"
          placeholder="Company Name"
          maxlength="60"
          (keyup.enter)="nextStepper($event)"
          autofocus
        />
        <label for="company_name">Client Name</label>
      </div>
      <div class="row row-cols-2 mt-4">
        <div>
          <button
            type="button"
            class="btn btn-dark w-100 btn-lg"
            (click)="onCloseDialogue()"
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary w-100 btn-lg"
            [disabled]="!clientForm.value?.company_name"
            (click)="nextStepper($event)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div id="contact-step" *ngIf="currentStep === 2">
      <div>
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="addrLookupData"
            placeHolder="Address Lookup"
            searchKeyword="full_address"
            [initialValue]="clientForm.value.addressLookup"
            [itemTemplate]="addressTemplate"
            [addInitialKey]="true"
            (inputChanged)="onAddressSearch($event)"
            (selected)="addressSelected($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #addressTemplate let-item>
          @if (item?.key === 0) {
          <div>Enter address manually</div>
          } @else {
          <div>
            <span
              ><b>{{ item.address1 + " " + item.address2 }}</b
              >,</span
            ><br />
            <small
              >{{ item.address3 ? item.address3 + " " : "" }}
              {{ item.city_name }} {{ item.state_name }}
              {{ item.postcode }}</small
            >
          </div>
          }
        </ng-template>
        <div *ngIf="enteredAddresslookup === true || updateView === true">
          <div
            class="alert alert-danger"
            style="font-weight: 500"
            *ngIf="
              clientForm.controls['address1'].invalid ||
              clientForm.controls['address2'].invalid ||
              clientForm.controls['city_name'].invalid ||
              clientForm.controls['postcode'].invalid
            "
          >
            Unable to get exact address, please fill in missing information
          </div>
          <!-- Address fields -->
          <div class="row row-cols-1 g-2">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                id="address1"
                formControlName="address1"
                maxlength="30"
                autocomplete="off"
                placeholder="Street No."
              />
              <label for="address1">Street Number</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                id="address2"
                maxlength="100"
                formControlName="address2"
                autocomplete="off"
                placeholder="Street"
              />
              <label for="address2">Street Name</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                id="address3"
                formControlName="address3"
                maxlength="100"
                autocomplete="off"
                placeholder="Unit"
              />
              <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                id="city_name"
                formControlName="city_name"
                maxlength="100"
                (input)="forceUppercaseConditionally($event)"
                autocomplete="off"
                placeholder="City"
              />
              <label for="city_name">Suburb</label>
            </div>

            <div class="form-floating">
              <input
                class="form-control"
                type="tel"
                id="postcode"
                formControlName="postcode"
                autocomplete="off"
                placeholder="Post Code"
                maxlength="30"
              />
              <label for="postcode">Post Code</label>
            </div>
            <div class="form-floating">
              <select class="form-select" formControlName="state_code">
                <option value="" selected disabled>State</option>
                <option *ngFor="let state of stateData" [value]="state.code">
                  {{ state.code }}
                </option>
              </select>
              <label for="">State</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-2 mt-4">
        <div>
          <button
            type="button"
            class="btn btn-dark w-100 btn-lg"
            (click)="previousStepper()"
          >
            Back
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary w-100 btn-lg"
            (click)="updateData()"
            [disabled]="!clientForm.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
