import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { DIALOG_DATA } from '../../../../global.variable';
import { PagesService } from '../../../pages/pages/pages.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-prompter',
  templateUrl: './prompter.component.html',
  styleUrl: './prompter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrompterComponent implements OnInit {
  @Input() promptData: any = [];
  @Input() preview: boolean = false;
  selectedPagePrompts: any = [];
  page: number = 0;

  requiredField: any;
  constructor(
    @Optional() protected dialogRef: DialogRef,
    private pageService: PagesService,
    @Inject(DIALOG_DATA) public dialogueData: any
  ) {}

  ngOnInit(): void {
    if (this.dialogueData) {
      this.promptData = this.dialogueData?.promptData;
      this.preview = this.dialogueData?.preview;
    }

    if (this.promptData?.length) {
      this.selectedPagePrompts = this.promptData[0];
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.promptData?.length) {
      this.selectedPagePrompts = this.promptData[0];
    }
  }

  changePagination(next = true) {
    this.page = next ? this.page + 1 : this.page - 1;
    if (this.page <= this.promptData?.length)
      this.selectedPagePrompts = this.promptData[this.page];
  }
  selectCheckBox(field: any, option: any) {
    if (field?.value?.includes(option)) {
      field.value = field?.value?.filter((item: any) => item !== option);
    } else {
      if (field?.value?.length < field?.allowedSelections) {
        field.value.push(option);
      } else {
        field?.value?.shift();
        field.value.push(option);
      }
    }
  }

  getSubOptions(field: any) {
    return (
      field?.options?.find((item: any) => item?.key == field?.value)
        ?.selectSubOptions || null
    );
  }
  closeDalog(value?: any) {
    this.dialogRef?.close(value);
  }
  validatepage() {
    return Boolean(
      this.getRequiredFieldWithoutValue([this.selectedPagePrompts])
    );
  }
  valdiateForm() {
    this.requiredField = this.getRequiredFieldWithoutValue(this.promptData);

    return Boolean(this.requiredField);
  }

  getRequiredFieldWithoutValue(promptData: any): string | null {
    function nestedConditionCheck(val: any, key: string) {
      if (Array.isArray(val)) {
        return val?.includes(key);
      } else {
        return key === val;
      }
    }
    function processObject(obj: any): string | null {
      if (obj.required) {
        if (!obj.value?.length) {
          return obj.promptLabel || obj.field;
        }

        if (obj?.options?.length) {
          for (const subOption of obj.options) {
            if (
              subOption?.selectSubOptions?.length &&
              nestedConditionCheck(obj?.value, subOption?.key)
            ) {
              for (const item of subOption?.selectSubOptions) {
                const result = processObject(item);
                if (result) {
                  return result;
                }
              }
            }
          }
        }
      }
      return null;
    }

    for (const pagePrompt of promptData) {
      for (const item of pagePrompt) {
        const result = processObject(item);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  savePromtData() {
    let savedData: any = [];
    for (const item of this.promptData) {
      savedData.push(...this.getValues(item));
    }
    this.dialogRef?.close(savedData);
  }
  getValues(data: any, outerItem?: any, key?: any) {
    const pageData: any = [];
    for (const innerItem of data) {
      if (outerItem && key && outerItem?.value != key) {
        continue;
      }

      if (innerItem.type === 'input') {
        const options = innerItem.options || [];
        const saveOptions: any = [];
        for (const optionItem of options) {
          if (optionItem.selectSubOptions) {
            saveOptions.push(
              ...this.getValues(
                optionItem.selectSubOptions,
                innerItem,
                optionItem?.key
              )
            );
          }
        }

        let data: any = {
          description: innerItem.description,
          value: innerItem.value,
          show_in_reports: innerItem.showInReports,
        };
        if (saveOptions.length > 0) {
          data.options = saveOptions;
        }
        pageData.push(data);
      }
    }
    return pageData;
  }
}
