import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, getFormattedDateTime } from '../../../../global.variable';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrl: './datetime-picker.component.scss',
})
export class DatetimePickerComponent {
  errorMessage: any;

  startDateTime: any;
  endDateTime: any;
  constructor(
    protected dialogueRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogData: any
  ) {} // private toastrService: NbToastrService //

  ngOnInit(): void {
    if (this.dialogData?.dateRange) {
      this.startDateTime = getFormattedDateTime(
        this.dialogData.dateRange?.start
      );
      this.endDateTime = getFormattedDateTime(this.dialogData.dateRange?.end);
    }
  }

  saveDateRange() {
    this.dialogueRef.close({
      start: new Date(this.startDateTime),
      end: new Date(this.endDateTime),
    });
  }
  onDismiss() {
    this.dialogueRef.close('close');
  }
}
