import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { PagesService } from '../../../pages/pages/pages.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'gtapp-add-edit-monitoring-company',
  templateUrl: './add-edit-monitoring-company.component.html',
  styleUrl: './add-edit-monitoring-company.component.scss',
})
export class AddEditMonitoringCompanyComponent implements OnInit {
  @Input() updateView: any;
  @Input() name: String = '';
  @Input() companyData: any;
  companyForm: UntypedFormGroup = new UntypedFormGroup({
    company_name: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private customerService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    @Optional()
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogueData: any,

    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.updateView = this.dialogueData?.updateView;
    this.name = this.dialogueData?.name;
    this.companyData = this.dialogueData?.companyData;
    if (this.updateView === false) {
      this.companyForm.reset();
      if (this.name) {
        this.companyForm.controls['company_name'].setValue(this.name);
      }
    } else {
      this.companyForm.controls['company_name'].setValue(
        this.companyData.company_name
      );
    }
  }
  updateData() {
    if (this.companyForm.valid) {
      var requestData = this.companyForm.value;
      this.spinnerService.show();
      if (!this.updateView) {
        this.customerService
          .createMonitoringCompany(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.onCloseDialogue(response);
              this.companyForm.reset();
              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        requestData['id'] = this.companyData.id;
        this.customerService
          .updateMonitoringCompanyDetailById(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);
              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    }
  }
  onCloseDialogue(value?: any) {
    this.dialogRef.close(value);
  }
}
