<!-- TODO: If address is incomplete show this message -->

<div *ngIf="incompleteAddress">
  <div class="form-floating">
    <input
      type="text"
      class="form-control"
      id="address1"
      formControlName="address1"
      placeholder="Street Number"
    />
    <label for="address1">Street Number</label>
  </div>

  <div class="form-floating">
    <input
      type="text"
      class="form-control"
      id="postcode"
      formControlName="postcode"
      placeholder="Post Code"
    />
    <label for="postcode">Post Code</label>
  </div>

  <!-- Add any other mandatory fields here -->
</div>

<div *ngIf="addressValue">
  <dt class="small fw-semibold mx-2">
    {{ addressValue }}
    <span (click)="editAddress()">
      <i class="fa-regular fa-trash-can fa-2x"></i>
    </span>
  </dt>
</div>
