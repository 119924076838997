import { Component, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { PagesService } from '../../../pages/pages/pages.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import { DataCheckService } from '../../services/data-check.service';
import { MustMatch } from '../../validators/must-match-validator';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { LoadingSpinnerService } from './../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'gtapp-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: UntypedFormGroup = new UntypedFormGroup(
    {
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirm_password: new UntypedFormControl(null, [
        MustMatch('password', 'confirm_password'),
      ]),
    },
    {
      validators: MustMatch('password', 'confirm_password'),
    }
  );
  showPassword: boolean = false;
  userData: any;
  isSuperUser: any;
  constructor(
    @Optional() protected dialogRef: DialogRef,
    private spinnerService: LoadingSpinnerService,
    private router: Router,
    private profileService: ProfileService,
    private appService: AppService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    private dialogService: ModelDialogueService
  ) {
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
  }

  ngOnInit(): void {}
  changePassword(val?: any) {
    if (this.passwordForm.valid) {
      if (this.isSuperUser === true) {
        this.profileService
          .changeAdminPassword(this.passwordForm.value)
          .subscribe((response) => {
            if (response['status'] == 'success') {
              if (this.isSuperUser === true) {
                this.toasterService.setMessage({
                  errorMessage: '',
                  successMessage: response['message'],
                });
                this.dialogRef.close();
              }
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.spinnerService.hide();
            }
          });
      } else {
        let dialogMsg = 'Are you sure you want to reset your password?';
        const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          data: {
            title: 'Change Password',
            message: dialogMsg,
          },
        });
        dialogRef.afterClosed().subscribe((value) => {
          if (value === true) {
            this.spinnerService.show();

            this.spinnerService.hide();
            this.profileService
              .changePassword(this.passwordForm.value)
              .subscribe((response) => {
                if (response['status'] == 'success') {
                  this.toasterService.setMessage({
                    errorMessage: '',
                    successMessage: response['message'],
                  });
                  this.dialogRef.close();
                } else {
                  this.toasterService.setMessage({
                    errorMessage: '',
                    successMessage: response['message'],
                  });
                  this.spinnerService.hide();
                }
              });
          }
        });
      }
    } else {
    }
  }
  //Method to change the password input type
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  onCloseDialogue() {
    this.dialogRef.close();
  }
}
