import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { DeviceInfoService } from '../../../shared/services/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService
  ) {}

  getGuardData() {
    return this.http.get('/api/users/guards/');
  }
  getUserData(params: any) {
    return this.http.get('/api/users/', { params: params });
  }

  userEnableDisable(id: any) {
    return this.http
      .patch(`api/users/${id}/`, {})
      .pipe(map((response: any) => response));
  }
  async deleteUserById(id: any) {
    const body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };

    return lastValueFrom(
      this.http
        .post(`api/users/${id}/delete_user/`, body)
        .pipe(map((response: any) => response))
    );
  }
  getClassByState(stateId: any) {
    return this.http.get(`api/security_license/`, {
      params: { stateId: stateId, byState: 1, lic_class: 1 },
    });
  }

  addNewClass(data: any) {
    const body = data;
    return this.http
      .post(`api/security_license/add_new_class/`, body)
      .pipe(map((response: any) => response));
  }
  async addLicense(requestData: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`api/security_license/`, body, { params: params })
        .pipe(map((response: any) => response))
    );
  }

  async addUser(requestData: any, params?: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`api/users/`, body, { params: params })
        .pipe(map((response) => response))
    );
  }
  async editUser(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`api/users/${requestData.id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async updateUser(requestData: any, params: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`api/users/${requestData.id}/`, body, { params: params })
        .pipe(map((response) => response))
    );
  }
  getUserById(user_id: any) {
    return this.http.get(`api/users/${user_id}/`);
  }

  getSuperUserById(user_id: any) {
    return this.http.get(`admin/user/${user_id}/`, {});
  }
  deleteSuperUserById(user_id: any) {
    return this.http.post(`admin/user/${user_id}/delete/`, {});
  }

  editSuperAdmin(requestData: any, id: any) {
    const body = requestData.data;
    return this.http
      .put(`/admin/user/${id}/`, body)
      .pipe(map((response) => response));
  }
  getUserGroups(params: any) {
    return this.http.get('/api/user_group/', { params: params });
  }

  getUserGroupById(id: any) {
    return this.http.get(`/api/user_group/${id}/`, {});
  }

  saveUserGroup(requestData: any, params?: any) {
    const body = { ...requestData };

    return this.http
      .post('/api/user_group/', body, { params: params })
      .pipe(map((response) => response));
  }
  updateUserGroup(id: Number, requestData: any, params?: any) {
    const body = { ...requestData };

    return this.http
      .put(`/api/user_group/${id}/`, body, { params: params })
      .pipe(map((response) => response));
  }
  deleteUserGroup(id: Number) {
    return this.http.post(`/api/user_group/${id}/delete/`, {});
  }

  addUsersToGroup(id: any, requestData: any, params?: any) {
    const body = { ...requestData };

    return this.http
      .post(`/api/user_group/${id}/update_users/`, body, { params: params })
      .pipe(map((response) => response));
  }
  getUserUpdateLog(requestData?: any, params?: any) {
    const body = { ...requestData };
    return this.http
      .post('/api/events/', body, { params: params })
      .pipe(map((response) => response));
  }
  async exportUserUpdateLog(requestData?: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };

    return lastValueFrom(
      this.http
        .post('/api/events/export/', body, { params: params })
        .pipe(map((response) => response))
    );
  }
  getExportLog(params?: any) {
    return this.http
      .get('/api/events/', { params: params })
      .pipe(map((response) => response));
  }

  getUserLastLocation(params?: any) {
    return this.http
      .get('/api/user_analytics/', { params: params })
      .pipe(map((response) => response));
  }
  getCurrentlyClockedUsers(params?: any) {
    return this.http
      .get('/api/users/', { params: params })
      .pipe(map((response) => response));
  }
  getUserUpdateLogById(requestData?: any, params?: any) {
    const body = { ...requestData };
    return this.http
      .post('/api/events/', body, { params: params })
      .pipe(map((response) => response));
  }
}
